import { Component } from 'react';

import Card from '../Card';
import Avatar from '../Avatar';
import List from '../List';
import Button from '../Button';
import Image from '../Image';
import ReadMore from '../ReadMore';

import './index.css';

import { withRouter } from 'react-router';
import { BRAND_PATH } from '../../router/config';
import { PLATFORM } from '../../constants/platforms';
import { formatStatisticsByLevel, assembleFullName } from '../../utils/util';

const STATISTICS = {
  followers: {
    label: 'Total Followers'
  },
  likes: {
    label: 'Total Likes'
  }
};

class InfluencerCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0
    };
  }

  render() {
    const {
      props: {
        data: {
          first_name,
          last_name,
          user: id = '',
          avatar_base_url = '',
          avatar = '',
          interests = '',
          tiktok_statistics = {},
          youtube_statistics = {},
          instagram_statistics = {},
          instagram_story_statistics = {}
          //statistics = {}
          //statistics: {
          //  //followers: {},
          //  //likes: {}
          //  followers = 0,
          //  likes = 0
          //}
        },
        history: {
          push
        }
      },
      state: {
        selected
      }
    } = this;

    const categories = interests ? interests.split(':').splice(0, 9) : [];
    const platforms = [
      {
        name: 'instagram',
        value: instagram_statistics
      },
      {
        name: 'instagram_story',
        value: instagram_story_statistics
      },
      {
        name: 'youtube',
        value: youtube_statistics
      },
      {
        name: 'tiktok',
        value: tiktok_statistics
      }
    ]
    //const platforms = [
    //  'instagram',
    //  'instagram_story',
    //  'youtube',
    //  'tiktok'
    //]

    return (
      <Card
        className="influencer-card"
        top={
          <div className="flex-column">
            <div className="influencer-card-top flex-row flex-between">
              <Avatar
                avatar={`${avatar_base_url}${avatar}`}
                className="influencer-card-avatar"
                shape="square"
              />
              <div className="influencer-card-basic flex-column">
                <div className="influencer-card-title">{assembleFullName(first_name, last_name)}</div>
                <List className="influencer-card-categories flex-grow flex-start">
                  {
                    categories.map((each, i) => {
                      return (
                        <Button
                          key={i}
                          colorSet="purple"
                          clickable={false}
                          className="btn-influencercard"
                        >
                          {each}
                        </Button>
                      );
                    })
                  }
                </List>
              </div>
            </div>
            <div className="influencer-card-top flex-row flex-between">
              <div className="influencer-card-platforms flex-row flex-between">
                {
                  platforms.map((each, i) => {
                    return <div
                      key={i}
                      onClick={() => this.setState({ selected: i })}
                      className={`${selected === i ? '' : 'un'}selected-platform`}
                    >
                      <Image className="influencer-card-platform" image={PLATFORM[each.name.toLowerCase()].icon} />
                    </div>
                  })
                }
              </div>
              <div className="influencer-card-statistics flex-row flex-between">
                {
                  Object.keys(STATISTICS).map((each, i) => {
                    const { label } = STATISTICS[each];
                    return (
                      <div
                        key={i}
                        className="influencer-card-statistics-data flex-column flex-center"
                      >
                        <div>{formatStatisticsByLevel(platforms[selected]['value']&&platforms[selected]['value'][each])}</div>
                        <div className="influencer-card-statistics-label">{label}</div>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          </div>
        }
        bottom={
          <div className="width-inherit flex-row-reverse">
            <ReadMore onClick={() => push(`${BRAND_PATH}/influencer/${id}`)} />
          </div>
        }
      />
    )
  }
}

export default withRouter(InfluencerCard);
