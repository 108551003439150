import { Component } from 'react';

import Button from '../Button';
import Select from '../Select';
import Input from '../Input';
import Avatar from '../Avatar';
import InboxItem from './components/InboxItem';
import Message from './components/Message';
import NewMessage from './components/NewMessage';

import request from '../../service/request';

import './index.css';

let timer = '';

export default class Inbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectValue: 'allMessage',
      isNewMessage: false,
      allMessage: 0,
      unread: 0,
      archived: 0,
      room: {
        avatar_base_url:'',
        avatar:'',
        name:''
      },
      roomList: [
        //{
        //  avatar_base_url:'',
        //  avatar: '',
        //  latest_message: 'messagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessage',
        //  date: 'timestamp',
        //  name: 'name',
        //  has_new: false
        //},
        //{
        //  avatar_base_url:'',
        //  avatar: '',
        //  latest_message: 'messagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessage',
        //  date: 'timestamp',
        //  name: 'name',
        //  has_new: true
        //},
        //{
        //  avatar_base_url:'',
        //  avatar: '',
        //  latest_message: 'messagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessage',
        //  date: 'timestamp',
        //  name: 'name',
        //  has_new: false
        //},
        //{
        //  avatar_base_url:'',
        //  avatar: '',
        //  latest_message: 'messagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessage',
        //  date: 'timestamp',
        //  name: 'name',
        //  has_new: true
        //},
        //{
        //  avatar_base_url:'',
        //  avatar: '',
        //  latest_message: 'messagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessage',
        //  date: 'timestamp',
        //  name: 'name',
        //  has_new: false
        //}
      ],
      message: '',
      messages: [
        //{
        //  avatar_base_url: '',
        //  from_avatar: '',
        //  from_name: 'from_name',
        //  message: 'message',
        //  date: 'date'
        //}
        //{
        //  avatar_base_url:'',
        //  avatar: '',
        //  name: 'name',
        //  message: 'messagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessagemessage',
        //  timestamp: 'timestamp'
        //}
      ]
    };
    this.fetchRoomList = this.fetchRoomList.bind(this);
    this.fetchMessages = this.fetchMessages.bind(this);
    this.selectInbox = this.selectInbox.bind(this);
    this.send = this.send.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.updateParams = this.updateParams.bind(this);
    this.newMessage = this.newMessage.bind(this);
    this.newMessageSend = this.newMessageSend.bind(this);
  }

  newMessage() {
    this.setState({isNewMessage: true});
  }

  newMessageSend(data) {
    //TODO
    this.setState({isNewMessage: false});
  }

  updateParams(event) {
    this.setState({
      selectValue: event.target.value
    });
  }

  async fetchRoomList(isLoading = true) {
    const { room } = this.state;
    const { callback } = this.props;
    const {
      data: {
        count,
        results
      } = {}
    } = await request(['message.list.room', {
      query: {
        page: 1,
        page_size: 100
      }
    }], {isLoading}) || '';
    const unread = results.filter(each => each.has_new == true).length;
    this.setState({
      roomList: results,
      room: JSON.stringify(room) == '{}' ? results[0] : room,
      allMessage: count,
      unread,
      archived: count - unread
    }, async () => {
      await this.fetchMessages(isLoading);
      callback(this.state.room);
    });
  }

  async fetchMessages(isLoading = true) {
    const { 
      state: {
        room: {
          related_user_id
        }
      }
    } = this
    const {
      data: {
        results
      } = {}
    } = await request([`message.read.user.${related_user_id}`, {
      data: {
        id: related_user_id
      },
      query: {
        max_count: 20
      }
    }], {isLoading}) || '';
    this.setState({ messages: results?.reverse() });
  }

  async selectInbox(room) {
    const { callback } = this.props;
    room.has_new = false;
    callback(room);
    this.setState({
      room
    }, async () => {
      await this.fetchMessages();
    });
    this.setState({isNewMessage: false}, () => {
      this.scrollToBottom();
    });
  }

  async send() {
    const { 
      state: {
        message,
        messages=[],
        room: {
          related_user_id
        }
      }
    } = this;
    if(message == '') {
      return;
    }
    const { data } = await request([`message.create.user.${related_user_id}`, {
      data: {
        message: message
      }
    }], {isLoading: false});
    this.setState({
      messages: messages.concat([data]),
      message: ''
    }, () => {
      this.scrollToBottom();
    });
  }

  async componentDidMount() {
    await this.fetchRoomList();
    const { 
      state: {
        roomList
      },
      props: {
        name
      }
    } = this;
    roomList.map(each => {
      if(each.name == name) {
        this.selectInbox(each);
      }
    })
    timer && clearInterval(timer);
    timer = setInterval(async () => {
      await this.fetchRoomList(false);
    }, 1000 * 15);
    this.scrollToBottom();
  }

  componentWillUnmount() {
    timer && clearInterval(timer);
  }

  scrollToBottom() {
    this.messagesEnd.scrollIntoView({ behavior: 'auto' });
  }


  render() {
    const {
      state: {
        selectValue,
        isNewMessage,
        roomList = [],
        messages = [],
        message = '',
        room: {
          avatar_base_url = '',
          avatar = '',
          name = ''
        },
        allMessage,
        unread,
        archived
      },
      props: {
        className
      }
    } = this;
    return (
      <div className="flex-row">
        <div className="inbox-list-wrap flex-column">
          <div className="inbox-buttons">
            <Button className="btn-inboxnewmessage" onClick={this.newMessage}>New Message</Button>
            <Select 
              value={selectValue}
              onChange={this.updateParams}
              width='22.65vw'
              height='3.3283vw'
            >
              <option style={{display: 'none'}} value='allMessave'>All messages ({allMessage})</option>
              <option value='allMessage'>All messages ({allMessage})</option>
              <option value='inbox'>Inbox ({allMessage})</option>
              <option value='unread'>Unread ({unread})</option>
              <option value='archived'>Archived ({archived})</option>
            </Select>
          </div>
          <div className='inbox-list flex-column flex-start'>
            {
              roomList.map((each, key) =>
              {
                if(selectValue == 'allMessage' || selectValue == 'inbox') {
                  return  <InboxItem onClick={() => this.selectInbox(each)} className='inbox-item' key={key} data={each} />
                } else if(selectValue == 'unread' && each.has_new == true) {
                  return  <InboxItem onClick={() => this.selectInbox(each)} className='inbox-item' key={key} data={each} />
                } else if(selectValue == 'archived' && each.has_new == false) {
                  return  <InboxItem onClick={() => this.selectInbox(each)} className='inbox-item' key={key} data={each} />
                }
              }
              )
            }
          </div>
        </div>
        {
          isNewMessage ? 
            <div>
              <NewMessage 
                onClick={this.newMessageSend}
              />
            </div>
            :
            <div className={`inbox-message-panel flex-column flex-between ${className}`}>
              <div className={`flex-row flex-start-center inbox-message-title-container ${className}`}>
                <div className='flex-row flex-start-center'>
                  <Avatar
                    avatar={`${avatar_base_url}${avatar}`}
                    className="inbox-messagepanel-avatar"
                  />
                  <div className='inbox-message-title-name'>{name}</div>
                </div>
              </div>
              <div className={`inbox-message-list flex-column flex-start  ${className}`}>
                {
                  messages.map((each, key) => {
                    return(
                      <div key={key}>
                        <Message className='inbox-message' data={each} />
                      </div>
                    )
                  })
                }
                <div style={{ float:'left', clear: 'both' }}
                  ref={(el) => { this.messagesEnd = el; }}>
                </div>
              </div>
              <div className='inbox-send flex-row flex-center'>
                <Input 
                  onChange={(event) => this.setState({message: event.target.value})}
                  className='inbox-send-input'
                  value={message}
                  width='30vw'//'39.06vw'
                  height='2.6vw'
                />
                <Button className='inbox-send-btn' onClick={this.send} colorSet={message ? 'blue' : 'gray'}>SEND</Button>
              </div>
            </div>
        }
      </div>
    );
  }
}
