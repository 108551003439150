import { Component } from 'react';
import {INFLUENCER_ROUTE, INFLUENCER_PATH} from '../../router/config';
//import Navigation from '../../layout/Navigation';
import InfluencerNavigation from '../../layout/InfluencerNavigation';

import ChatBubble from '../../components/ChatBubble';
//import NotificationsRounded from '../../components/NotificationsRounded';
import Avatar from '../../components/Avatar';
import Logo from '../../components/Logo';
import Bubble from '../../components/Bubble';
import Footer from '../../components/Footer';

import { observer } from 'mobx-react';
import { RootStoreContext } from '../../store';
import authStore from '../../store/user/auth';

import request from '../../service/request';

import './index.css';

const auth = authStore();

const configMenu = ['Social Accounts', 'Language', 'Help', 'Log Out'];
class Influencer extends Component {

  static contextType = RootStoreContext;

  constructor(props) {
    super(props);
    this.state = {
      msgBubbleVisible: false,
      avatarBubbleVisible: false,
      roomList: []
    }
    this.clickAvatar = this.clickAvatar.bind(this);
    this.clickSettingBubble = this.clickSettingBubble.bind(this);
    this.fetchRoomList = this.fetchRoomList.bind(this);
    this.getInfluencerInfo = this.getInfluencerInfo.bind(this);
  }

  clickMsg() {
    this.setState({msgBubbleVisible: !this.state.msgBubbleVisible}, async () => {
      await this.fetchRoomList();
    });
  }

  clickAvatar() {
    this.setState({avatarBubbleVisible: !this.state.avatarBubbleVisible});
  }

  clickSettingBubble(item) {
    const {
      props: {
        history: { push }
      }
    } = this;
		
    if(item === 'Log Out') {
      auth.logout();
      push('/');
    } else {
      push({
        pathname: INFLUENCER_ROUTE.routes[3].path,
        params: item
      });
    }
  }

  async fetchRoomList() {
    const {
      data: {
        results
      } = {}
    } = await request(['message.list.room', {
      query: {
        page: 1,
        page_size: 3
      }
    }]) || '';
    this.setState({
      roomList: results
    })
  }

  async getInfluencerInfo() {
    const { influencer } = this.context;
    const { 
      data: {
        user: {
          email = '',
          id = ''
        },
        avatar,
        avatar_base_url,
        first_name,
        last_name,
        phone_number,
        country = '',
        gender,
        birthdate,
        ssn,
        address,
        address2,
        city,
        state,
        postal_code,
        pay_from,
        pay_to,
        interests
      }
    } = await request(['me.read']);
    influencer.setInfo({
      id,
      avatar,
      baseUrl: avatar_base_url,
      firstName: first_name,
      lastName: last_name,
      email,
      birthYear: birthdate.split('-')[0],
      birthMonth: birthdate.split('-')[1],
      birthDay: birthdate.split('-')[2],
      country,
      gender,
      phoneNum: phone_number,
      address,
      address2,
      city,
      state,
      postalCode: postal_code,
      ssn,
      payRageStart: pay_from,
      payRageEnd: pay_to,
      interests
    });
  }

  componentDidMount() {
    this.getInfluencerInfo();
  }

  render() {
    const {
      state: {
        roomList
      },
      props: {
        match: { url },
        history: { push }
      },
      context: {
        influencer: {
          info: {
            baseUrl,
            avatar
          }
        }
      }
    } = this;
    const { routes } = INFLUENCER_ROUTE;
    const route = routes.find(each => (each.path === url));
    const Container = route.component || {};
    return (
      <div>
        <InfluencerNavigation
          path={url}
          routes={routes}
          onClick={push}
          itemClassName="influencer-nav-item"
          selectedItemClassName="influencer-nav-item_selected"
          prefix={(
            <Logo className="influencer-nav-logo"/>
          )}
          suffix={(
            <div className="flex-row flex-start-center">
              <div className="influencer-nav-chat-bubble" onClick={() => {
                this.clickMsg();
              }}>
                <ChatBubble isNews={true} sx={{color: 'rgb(96, 0, 255)'}}/>
              </div>
              {/*<div className="influencer-nav-notifications-rounded">
                <NotificationsRounded isNews={true} sx={{color: 'rgb(96, 0, 255)'}}/>
              </div>*/}
              <div className="influencer-nav-credits">
                <div>6/10</div>
                <div>credits</div>
              </div>
              <div className="relative">
                <div onClick={this.clickAvatar}>
                  <Avatar
                    avatar={`${baseUrl}${avatar}`}
                    className="influencer-nav-avatar"
                  />
                </div>
                <Bubble
                  visible={this.state.msgBubbleVisible}
                  title="Message"
                  colorSet='purple'
                  className="influencer-nav-msg-bubble absolute"
                >
                  {
                    <div>
                      {
                        roomList?.map((each, key) => {
                          return <div key={key}>{each.latest_message}</div>
                        })
                      }
                      <div onClick={() => push(`${INFLUENCER_PATH}/inbox`)} className='influencer-nav-msg-bubble-text'>See All</div>
                    </div>
                  }
                </Bubble>
                <Bubble
                  visible={this.state.avatarBubbleVisible}
                  title="Settings"
                  colorSet='yellow'
                  className="influencer-nav-avatar-bubble absolute"
                >
                  {
                    configMenu?.map((each, i) => {
                      return (
                        <div 
                          className="influencer-nav-avatar-bubble-text"
                          key={i}
                          onClick={() => {
                            this.clickSettingBubble(each);
                          }}
                        >
                          {each}
                        </div>
                      )
                    })
                  }
                </Bubble>
              </div>
            </div>
          )}
          footer={(
            <Footer />
          )}
        >
          <div className="influencer-container">
            <Container />
          </div>
        </InfluencerNavigation>
      </div>
    );
  }
};

export default observer(Influencer);