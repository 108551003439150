export const PLATFORM = {
  instagram: {
    label: 'Instagram',
    icon: require('../assets/images/logo/logo-instagram.webp')
  },
  instagram_story: {
    label: 'Instagram Story',
    icon: require('../assets/images/logo/logo-instagram_story.webp')
  },
  youtube: {
    label: 'YouTube',
    icon: require('../assets/images/logo/logo-youtube.webp')
  },
  tiktok: {
    label: 'TikTok',
    icon: require('../assets/images/logo/logo-tiktok.webp')
  }
};
