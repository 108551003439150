import { Component } from 'react';

import Card from '../../../../../../../../components/Card';
import Avatar from '../../../../../../../../components/Avatar';

import './index.css';

import request from '../../../../../../../../service/request';

import { withRouter } from 'react-router';

class BrandList extends Component {
  constructor(props) {
    super(props);
    const { id = '' } = props.match.params;
    this.state = {
      id,
      data: []
    }
    this.load = this.load.bind(this);
  }

  async load() {
    const { id } = this.state;
    const { data } = await request([`influencer.read.${id}.top_brands`, {
    }]);
    this.setState({data});
  }

  componentDidMount() {
    this.load();
  }

  render() {
    const {
      state: {
        data = []
      }
    } = this;

    return (
      (data || []).map((each, i) => {
        const { avatar_base_url, avatar, company_name, collaboration_count } = each;
        return (
          <Card
            key={i}
            prefix={<Avatar className="brand-card-avatar" avatar={`${avatar_base_url}${avatar}`} />}
            title={company_name}
            subtitle={`[${collaboration_count}] Collaborations`}
            shadowed={true}
            className="brand-card"
            titleClassName="brand-card-title"
            subtitleClassName="brand-card-subtitle"
          />
        );
      })
    );
  }
}

export default withRouter(BrandList);