import { Component } from 'react';

import List from '../List';

export default class SelectableList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: {}
    };
  }

  render() {
    const {
      props: {
        list = [],
        component: Component = new Function(),
        update = new Function(),
        className = '',
        componentClassName: {
          className: componentClassName = '',
          selectedClassName = '',
          unselectedClassName = '',
          labelClassName = ''
        } = {},
        style = {},
        componentStyle: {
          style: componentStyle = {},
          selectedStyle = {},
          unselectedStyle = {},
          labelStyle = {}
        } = {}
      }
    } = this;
    let selected
    if (this.props.selected) {
      selected = this.props.selected
    } else {
      selected = this.state.selected
    }
    const onClick = i => {
      const newSelected = { ...selected, [i]: !selected[i]};
      this.setState({
        selected: newSelected
      });
      update(newSelected);
    };
    return (
      <List
        className={className}
        style={{style}}
      >
        {
          list.map(
            (each, i) =>
              <Component
                key={i}
                id={i}
                selected={selected[i]}
                {...each}
                onClick={onClick}
                className={componentClassName}
                selectedClassName={selectedClassName}
                unselectedClassName={unselectedClassName}
                labelClassName={labelClassName}
                style={componentStyle}
                selectedStyle={selectedStyle}
                unselectedStyle={unselectedStyle}
                labelStyle={labelStyle}
              />
          )
        }
      </List>
    );
  }
};
