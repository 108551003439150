import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';

import './index.css';

export default function SectionTitle(props = {}) {
  const { className = '', title } = props;
  return (
    <div className={`full-width section-title flex-row flex-between-center ${className}`}>
      <div className="section-title-title">{title}</div>
      <ArrowDropDownOutlinedIcon className="section-title-icon" />
    </div>
  );
}
