import { makeAutoObservable } from 'mobx';

class Influencer {

  info = {
    id: '',
    avatar: '',
    baseUrl: '',
    firstName: '',
    lastName: '',
    email: '',
    birthYear: '',
    birthMonth: '',
    birthDay: '',
    country: '',
    gender: '',
    phoneNum: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    postalCode: '',
    ssn: '',
    payRageStart: '',
    payRageEnd: '',
    interests: ''
  }
  
  constructor() {
    makeAutoObservable(this);
  }

  setInfo(info) {
    Object.assign(this.info, info);
  }
}

export default Influencer;

