import { Component } from 'react';

import SignLayout from '../../layout/SignLayout';
import Input from '../../components/Input';
import Button from '../../components/Button';
import ForgotPwd from './components/ForgotPwd';
import './index.css';
import jwt_decode from 'jwt-decode';

import request from '../../service/request';

import { HOME, BRAND_ROUTE, INFLUENCER_ROUTE } from '../../router/config';
import { RootStoreContext } from '../../store';
import { ROLE } from '../../store/user/auth';
// import { rootStore } from '../../store';
// const { exception } = rootStore;

export default class Signin extends Component {

  static contextType = RootStoreContext;

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      confirmedPassword: '',
      isShowForgotPwd: false,
      errorMsg: '',
      rememberMe: false,
      showPassword: false  
    };
    this.updateParams = this.updateParams.bind(this);
    this.signin = this.signin.bind(this);
    this.submit = this.submit.bind(this);
  }

  updateParams(event) {
    this.removeErrMsg()
    const name = event.target.name;
    const value = event.target.value;
    this.setState({[name]: value});
  }

  async signin() {
    const {
      state: { email, password, rememberMe },
      props: {
        match: { params: { role } },
        history: { push },
        location: {
          state: {
            redirectUrl = ''
          } = {}
        } = {}
      },
      context: {
        auth: {
          setRole
        } = {}
      }
    } = this;

    localStorage.removeItem('bcom_token');
    sessionStorage.removeItem('bcom_token');

    setRole(role);
    const {
      data: {
        detail = '',
        access = ''
      }
    } = await request(['token.create', {
      data: {
        email,
        password,
        remember_me: rememberMe 
      }
    }], {isErrorHandler: false}) || '';

    if (!detail) {
      const { user_role } = jwt_decode(access);
      if(user_role.toLowerCase() != role) {
        this.setState({errorMsg: 'No active account found with the given credentials'})
        // exception.message = 'No active account found with the given credentials';
        return;
      }

      if (role === 'influencer' && rememberMe) {
        localStorage.setItem('bcom_token', access);
        localStorage.setItem('bcom_email', btoa(email));
      } else {
        sessionStorage.setItem('bcom_token', access);
      }

      switch(role) {
      case 'brand':
        push(redirectUrl ? redirectUrl : BRAND_ROUTE.path.replace(':page', 'overview'));
        break;
      case 'influencer':
        push(redirectUrl ? redirectUrl : INFLUENCER_ROUTE.path.replace(':page', 'product'));
        break;
      default:
        push(HOME.path);
      };
    } else {
      this.setState({errorMsg: detail})
      // exception.message = detail;
    }
  }

  submit() {
    this.setState({
      isShowForgotPwd: false,
      isShowDialog: true
    });
  }
  removeErrMsg() {
    this.setState({errorMsg: ''})
  }

  componentDidMount() {
    const { 
      match: { 
        params: { role } 
      } 
    } = this.props;
    
    console.log(this.props.match.params)

    if (role === 'brand') {
      localStorage.removeItem('bcom_token');
      localStorage.removeItem('bcom_email');
      sessionStorage.removeItem('bcom_token');
      return;
    }

    if (role === 'influencer') {
      const savedEmail = localStorage.getItem('bcom_email');
      if (savedEmail) {
        this.setState({
          email: atob(savedEmail),
          rememberMe: true
        });
      }
    }
  }

  render() {
    const {
      state: {
        email,
        password,
        confirmedPassword,
        isShowForgotPwd,
        rememberMe
      },
      props: {
        match: {
          params: { role }
        }
      }
    } = this;
    return (
      <SignLayout title="NEW HERE?" role={role}>
        {
          !isShowForgotPwd ?
            <div className="login-block flex-column flex-start-center" >
              <div className="signin-title">WELCOME TO BCom</div>
              <div className="signin-subtitle">
                Sign in to your {role === ROLE[2].label.toLowerCase() ? '' : `${role} `}account
              </div>
              <div className="input-block">
                <Input
                  label="Email"
                  name='email'
                  value={email}
                  colorSet="gray"
                  className="input-email"
                  placeholder="Bcom@gmail.com"
                  onChange={this.updateParams}
                />
                <Input
                  label="Password"
                  name='password'
                  value={password}
                  colorSet="gray"
                  type={this.state.showPassword ? 'text' : 'password'}
                  className="input-password"
                  placeholder="password"
                  onChange={this.updateParams}
                  endAdornment={
                    <div 
                      className="password-toggle"
                      onClick={() => this.setState(prev => ({ showPassword: !prev.showPassword }))}
                    >
                      {this.state.showPassword ? 'Hide' : 'Show'}
                    </div>
                  }
                />
                {role === 'influencer' && (
                  <div className="remember-me-container">
                    <input
                      type="checkbox"
                      id="rememberMe"
                      checked={rememberMe}
                      onChange={(e) => this.setState({ rememberMe: e.target.checked })}
                    />
                    <label htmlFor="rememberMe">Remember me for 30 days</label>
                  </div>
                )}
                <div style={{
                  margin: '10px 0',
                  color: '#f00'
                }}>{this.state.errorMsg}</div>
                <div className="forgot-text" onClick={() => this.setState({isShowForgotPwd: true, password: ''})}>Forgot my password</div>
                <Button className="btn-signin" onClick={this.signin}>Sign In</Button>
              </div>
            </div>
            :
            <ForgotPwd
              email={email}
              password={password}
              confirmedPassword={confirmedPassword}
              callback={() => this.setState({isShowForgotPwd: false})}
            />
        }
      </SignLayout>
    );
  }
};
