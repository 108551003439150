import { Component } from 'react';

import BgImage from '../BgImage';

const DEFAULT_STYLE = {
  style: {
    width: '6.31vw',
    height: '6.31vw',
    edge: '0.9765vw',
    borderRadius: '50%'
  },
  selectedStyle: {
    backgroundColor: '#8657ff',
    opacity: 0.3
  },
  unselectedStyle: {
    boxSizing: 'border-box',
    border: '0.1953vw solid #8657ff'
  },
  labelStyle: {
    padding: '0.1302vw 0.0651vw',
    fontSize: '0.78125vw',
    backgroundColor: '#fff'
  }
};

export default class SelectableImage extends Component {
  render() {
    const {
      id,
      selected,
      image,
      label = '',
      plain = false,
      className = '',
      selectedClassName = '',
      unselectedClassName = '',
      labelClassName = '',
      style = {},
      style: { width, height, edge } = {},
      selectedStyle = {},
      unselectedStyle = {},
      labelStyle = {},
      onClick
    } = { ...DEFAULT_STYLE, ...this.props };
    plain && (style.edge = '0');

    return (
      <div
        className="relative unselectable"
        style={{ width, height, margin: plain ? '0' : edge }}
        onClick={() => onClick(id)}
      >
        {
          label ?
            <div className="absolute-center flex-row flex-center">
              <div className="flex-column flex-center">
                <div
                  className={`fit-content z-index-2 ${labelClassName}`}
                  style={{ opacity: selected ? 0.7 : 0.8, ...labelStyle }}
                >
                  {label}
                </div>
              </div>
        	</div>
            :
            null
        }
        <div
          className={`absolute z-index-2 ${className} ${selected
            ? ''
            : unselectedClassName
          }`}
          style={{ ...style, ...(selected ? selectedStyle : unselectedStyle) }}
        >
        </div>
        <BgImage
          image={image}
          className={`${className} ${selected
            ? selectedClassName
            : unselectedClassName
          }`}
          style={{ ...style, ...(selected ? selectedStyle : {}) }}
        />
      </div>
    );
  }
};
