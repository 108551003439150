import './index.css';

export default function TextArea(props = {}) {
  const {
    placeholder = '',
    className = '',
    value = '',
    onChange = new Function(),
    disabled
  } = props;
  return (
    <textarea
      className={`textarea ${className}`}
      value={value}
      placeholder={placeholder}
      onChange={evt => onChange(evt.target.value)}
      disabled={disabled}
    />
  );
}
