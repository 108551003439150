import Button from '../Button';

import './index.css';

export default function LoadMore(props = {}) {
  const {
    className = '',
    colorSet = 'white',
    onClick = new Function()
  } = props;
  return (
    <Button
      className={`btn-loadmore ${className}`}
      colorSet={colorSet}
      onClick={onClick}
    >
      Load More
    </Button>
  )
};
