import { Component } from 'react';
import NextTimeButton from '../../../../../../../../components/NextTimeButton';
import SelectButton from './components/SelectButton';

import './index.css';

import request from '../../../../../../../../service/request';
export default class Start extends Component {
  constructor(props) {
    super(props);
    this.select = this.select.bind(this);
  }

  async select() {
    const {
      props: {
        id,
        next = new Function()
      }
    } = this;
    const {
      data
    } = await request(['order.update.approve', {
      params: id,
      data: { approved: true }
    }]) || {};
    data && !data.detail && next({ order: data, pass: true });
  }

  render() {
    const {
      props: {
        id,
        data: {
          influencer,
          product,
          message
        },
        editable,
        className = '',
        next = new Function(),
        reject = new Function()
      },
      select
    } = this;

    return (
      <div className={`flex-column ${className}`}>
        <div className='flex-column flex-start'>
          <div className="brandcollabstart-title">Message For You</div>
          <div className="brandcollabstart-textarea">{message}</div>
        </div>
        {
          editable && (
            <>
              <SelectButton
                className="btn-brandcollabstart"
                product={product}
                influencer={influencer}
                select={select}
              />
              <NextTimeButton
                className="btn-brandcollabstart"
                id={id}
                influencer={influencer}
                callback={() => next({ pass: false })}
                reject={reject}
              />
            </>
          )
        }
      </div>
    );
  }
}
