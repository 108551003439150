import { Component } from 'react';
import {
  Chart,
  PieSeries
  // Legend
} from '@devexpress/dx-react-chart-material-ui';
import { Palette } from '@devexpress/dx-react-chart';

import './index.css';

import request from '../../../../../../../../service/request';

import {
  CHART_PALETTE,
  paletteCalculator
} from '../../../../../../../../utils/style';
import { toCamel } from '../../../../../../../../utils/string';

import { withRouter } from 'react-router';

class CollaborationChart extends Component{
  constructor(props) {
    super(props);
    const { id = '' } = props.match.params;
    this.state = {
      id,
      data: {}
    }
    this.getCollaborationPercentage = this.getCollaborationPercentage.bind(this);
  }

  async getCollaborationPercentage() {
    const { id } = this.state;
    const { data } = await request([`influencer.read.${id}.collaboration_percentage`], {
    });
    this.setState({data});
  }

  componentDidMount() {
    this.getCollaborationPercentage();
  }

  render() {
    const { data } = this.state;
    const list = Object.keys(data).map(each => ({
      category: toCamel(each),
      percentage: data[each]
    }));
    return (
      <div className="width-inherit">
        <div className="collab-chart-legend flex-row flex-wrap">
          {
            list.map((each, i) => (
              <div className="collab-chart-legend-item flex-row flex-center" key={i}>
                <div
                  className="collab-chart-palette"
                  style={{ backgroundColor: paletteCalculator(i) }}
                />
                <div>{each.category}</div>
              </div>
            ))
          }
        </div>
        <div className="collab-chart-content width-inherit flex-column flex-center">
          <Chart className="collab-chart flex-column" data={list}>
            <Palette scheme={CHART_PALETTE} />
            <PieSeries
              className="collab-chart"
              argumentField="category"
              valueField="percentage"
              pointComponent={
                props => <PieSeries.Point {...props} style={{ border: '0.0651vw solid red' }} />
              }
            />
            {/* <Legend /> */}
          </Chart>
        </div>
      </div>
    );
  }
}

export default (withRouter(CollaborationChart));