import { Component } from 'react';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import Panel from '../../../../components/Panel';
import PaymentList from './components/PaymentList';
import CollaborationList from './components/CollaborationList';
import InfluencerList from './components/InfluencerList';

import './index.css';

import { withRouter } from 'react-router';

import { BRAND_PATH } from '../../../../router/config';

const PANEL = [
  {
    Component: PaymentList,
    title: 'Payments',
    colorSet: 'gray',
    className: 'overview-content payment-list scroll-y'
  },
  {
    Component: CollaborationList,
    title: 'Collaboration',
    colorSet: 'gray',
    className: 'overview-content collaboration-list scroll-y',
    to: 'collaboration'
  },
  {
    Component: InfluencerList,
    title: 'Influencer Base',
    colorSet: 'gray',
    className: 'overview-content influencer-list-overview scroll-y',
    to: 'influencer'
  }
];

class Overview extends Component {
  render() {
    const {
      props: {
        history: {
          push
        }
      }
    } = this;
    return (
      <div className="overview">
        <div className="overview-panels flex-row width-inherit flex-between">
          {
            PANEL.map((each, i) => {
              const {
                Component,
                title,
                colorSet,
                className = '',
                to,
                style
              } = each;
              return (
                <Panel
                  key={i}
                  title={title}
                  bottom={
                    to && (
                      <div className="overview-list-seeall">
                        <div
                          className="flex-row-reverse flex-start-center"
                          onClick={() => push(`${BRAND_PATH}/${to}`)}
                        >
                          <ArrowForwardIosIcon className="overview-list-seeall-btn" />
                          <div className='overview-list-seeall-font'>See All</div>
                        </div>
                      </div>
                    )
                  }
                  colorSet={colorSet}
                  className="overview-list bg_white"
                  topClassName="overview-panel-top"
                  bottomClassName="overview-panel-bottom"
                  listClassName={className}
                  listStyle={style}
                >
                  <Component />
                </Panel>
              );
            })
          }
        </div>
      </div>
    );
  }
};

export default withRouter(Overview);
