import { makeAutoObservable } from 'mobx';

export default class Exception {

  message = ''
  
  constructor() {
    makeAutoObservable(this);
  }

  setMessage(msg) {
    this.message = msg;
  }
}