import App from '../App';
import {
  Home,
  Signin,
  Signup,
  Brand,
  Influencer,
  BrandOverview,
  BrandProductList,
  BrandProductDetails,
  BrandCollaboration,
  BrandCollaborationDetails,
  BrandInfluencerList,
  BrandInfluencerDetails,
  BrandInbox,
  BrandSettings,
  BrandLogout,
  InfluencerProducts,
  InfluencerProductDetails,
  InfluencerCollaboration,
  InfluencerInbox,
  InfluencerConfig,
  InfluencerCollaborationDetails
} from '../views';

import { PUBLIC_PATH } from '../constants/global';
import { ROLE } from '../store/user/auth';

import HomeIcon from '@mui/icons-material/Home';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import SettingsIcon from '@mui/icons-material/Settings';
import ForwardSharpIcon from '@mui/icons-material/ForwardSharp';

export const HOME = {
  path: PUBLIC_PATH,
  component: Home
};
export const BRAND_PATH = `${PUBLIC_PATH}/${ROLE[1].label.toLowerCase()}`;
export const INFLUENCER_PATH = `${PUBLIC_PATH}/${ROLE[2].label.toLowerCase()}`;
export const BRAND_ROUTE = {
  path: `${BRAND_PATH}/:page`,
  component: Brand,
  routes: [
    {
      path: `${BRAND_PATH}/overview`,
      component: BrandOverview,
      title: 'Overview',
      icon: props => <HomeIcon {...props} />
    },
    {
      path: `${BRAND_PATH}/product`,
      component: BrandProductList,
      title: 'Product List',
      icon: props => <ViewInArIcon {...props} />
    },
    {
      path: `${BRAND_PATH}/collaboration`,
      component: BrandCollaboration,
      title: 'Collaboration',
      icon: props => <LocalFireDepartmentIcon {...props} />
    },
    {
      path: `${BRAND_PATH}/influencer`,
      component: BrandInfluencerList,
      title: 'Influencer Base',
      icon: props => <PersonIcon {...props} />
    },
    {
      path: `${BRAND_PATH}/inbox`,
      component: BrandInbox,
      title: 'Message',
      icon: props => <EmailIcon {...props} />
    },
    {
      path: `${BRAND_PATH}/settings`,
      component: BrandSettings,
      title: 'Settings',
      icon: props => <SettingsIcon {...props} />
    },
    {
      path: `${BRAND_PATH}/logout`,
      component: BrandLogout,
      title: 'Log Out',
      icon: props => {
        const { sx } = props;
        Object.assign(sx, {transform: 'rotate(180deg)'});
        return(
          <ForwardSharpIcon { ...props} />
        )
      }
    }
  ]
};
export const INFLUENCER_ROUTE = {
  path: `${INFLUENCER_PATH}/:page`,
  component: Influencer,
  routes: [
    {
      path: `${INFLUENCER_PATH}/product`,
      component: InfluencerProducts,
      title: 'PRODUCT',
      tab: true
    },
    {
      path: `${INFLUENCER_PATH}/order`,
      component: InfluencerCollaboration,
      title: 'COLLAB',
      tab: true
    },
    {
      path: `${INFLUENCER_PATH}/inbox`,
      component: InfluencerInbox,
      title: 'INBOX',
      tab: true
    },
    {
      path: `${INFLUENCER_PATH}/config`,
      component: InfluencerConfig,
      title: 'CONFIG',
      tab: false
    }
  ]
};

export const AUTH_ROUTE = {
  path: `${PUBLIC_PATH}/:role/signin`,
  component: Signin
};

const GLOBAL_ROUTE = { path: '*', component: HOME.component };

const ROUTES = [
  AUTH_ROUTE,
  {
    path: `${PUBLIC_PATH}/:role/signup`,
    component: Signup
    // onEnter: function (nextState, replaceState) {
    //   replaceState(null, '/signin');
    // },
    // routes: [
    //   { path: '/brand', component: Brand },
    //   { path: '/influencer', component: Influencer }
    // ]
  },
  {
    path: `${BRAND_PATH}/product/:id`,
    component: BrandProductDetails
  },
  {
    path: `${BRAND_PATH}/collaboration/:id`,
    component: BrandCollaborationDetails
  },
  {
    path: `${BRAND_PATH}/influencer/:id`,
    component: BrandInfluencerDetails
  },
  {
    path: `${INFLUENCER_PATH}/product/:id`,
    component: InfluencerProductDetails
  },
  {
    path: `${INFLUENCER_PATH}/order/:id`,
    component: InfluencerCollaborationDetails
  },
  {
    path: PUBLIC_PATH,
    component: App,
    routes: [
      BRAND_ROUTE,
      INFLUENCER_ROUTE,
      GLOBAL_ROUTE
    ]
  },
  GLOBAL_ROUTE
];

export default ROUTES;
