export const toCamel = (val, sep = '-') =>
  val
    .split(sep)
    .reduce(
      (prev, each) => `${prev}${each
        ? `${each[0].toUpperCase()}${each.slice(1)}`
        : ''}`,
      ''
    );

export const parseQuery = search =>
  search.slice(1).split('&').reduce((prev, each) => {
    const [key, value] = each.split('=');
    prev[key] = decodeURIComponent(value);
    return prev;
  }, {});

export const assembleQuery = query =>
  query
    ? Object.keys(query).reduce((prev, each) => `${prev}${each}=${query[each]}&`, '?')
    : '';
