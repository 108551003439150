import { Component } from 'react';
import SectionTitle from '../../../../../../../../components/SectionTitle';
import CommentList from '../../../../../../../../components/CommentList';
import PublishedList from '../PublishedList';
import PublishCommentButton from './components/PublishCommentButton';

import './index.css';

import request from '../../../../../../../../service/request';

export default class Published extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brand_name: '',
      brand_title: '',
      comment: '',
      comments: []
    };
    this.addComment = this.addComment.bind(this);
    this.getComments = this.getComments.bind(this);
  }

  async addComment(params) {
    const {
      props: {
        id
      } = {}
    } = this;
    const {
      name: brand_name,
      title: brand_title,
      comment
    } = params;
    const {
      data
    } = await request(['order.create.comment.brand', {
      params: id,
      data: {
        brand_name,
        brand_title,
        text: comment
      }
    }]) || {};
    data && !data.detail && (await this.getComments());
  }

  async getComments() {
    const {
      props: {
        id
      } = {}
    } = this; 
    const {
      data: {
        results = []
      }
    } = await request(['order.list.comment', {
      params: id,
      query: {
        page: 1,
        page_size: 10
      }
    }]);
    results && this.setState({
      comments: results
    });
  }

  async componentDidMount() {
    await this.getComments();
  }

  render() {
    const {
      state: {
        comments
      },
      props: {
        data: {
          influencer,
          social_links = []          
        }
      }
    } = this;
    return (
      <div className="flex-column flex-start-center">
        <PublishedList links={social_links} influencer={influencer} status="published" />
        <SectionTitle title="Comments For Collaboration" />
        <PublishCommentButton
          className="flex-self-start btn-addcomment"
          influencer={influencer}
          submit={this.addComment}
        />
        <div className="flex-self-start">
          <CommentList className="brandcollabpublished-comment" data={comments} />
        </div>
      </div>
    );
  }
}
