import { Route } from 'react-router-dom';

function SubRoute(route) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={props => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} {...route} />
      )}
    />
  );
};

export default SubRoute;
