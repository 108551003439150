import { Component, createRef } from 'react';

import ReadMore from '../ReadMore';

import './index.css';

export default class FoldedTextArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      folded: true,
      overflow: false
    };
    this.detailsRef = createRef();
  }

  componentDidMount() {
    const element = this.detailsRef.current;
    this.setState({
      overflow: element.offsetHeight < element.scrollHeight ||
        element.offsetWidth < element.scrollWidth
    });
  }

  render() {
    const {
      props: {
        title,
        abstract,
        details,
        className= ''
      },
      state: {
        folded,
        overflow
      }
    } = this;

    return (
      <div className={className}>
        <div className="folded-textarea-title">{title}</div>
        {abstract && (<div className="folded-textarea-text">{abstract}</div>)}
        <div
          className={`folded-textarea-text folded-textarea-details folded-textarea-${overflow
            ? folded
              ? 'folded'
              : ''
            : 'all'
          }`}
          ref={this.detailsRef}
        >
          {details}
        </div>
        {
          overflow
            ? (
              <ReadMore
                folded={folded}
                reverse={true}
                colorSet="grey"
                className="folded-textarea-readmore"
                iconClassName="folded-textarea-readmore-icon"
                onClick={() => this.setState({ folded: !folded })}
              />
            )
            : <></>
        }
      </div>
    );
  }
}
