export const calTimeDiff = (start, end) => {
  const value = end - start;
  return {
    value,
    seconds: value / 1000,
    minutes: value / 1000 / 60,
    hours: value / 1000 / 60 / 60,
    days: value / 1000 / 60 / 60 /24
  };
}
