const DEFAULT_IMAGE = require('../../assets/images/bg/bg-sign-5.jpeg');

export default function Image (props = {}) {
  const { image, children, className = '', style = {}, onClick } = props;
  return (
    <div
      onClick={onClick}
      className={`bg-common border-radius-contain ${className}`}
      style={{
        backgroundImage: `url(${image || DEFAULT_IMAGE})`,
        ...style
      }}
    >
      {children}
    </div>
  );
}
