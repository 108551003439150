import { Component } from 'react';
import Button from '../Button';
import ConfirmedDialog from '../ConfirmedDialog';
import Avatar from '../Avatar';

import './index.css';

import request from '../../service/request';
import { assembleFullName } from '../../utils/util';

export default class  NextTimeButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
    this.reject = this.reject.bind(this);
    this.changeVisible = this.changeVisible.bind(this);
  }

  async reject() {
    const {
      props: {
        id,
        callback = new Function(),
        reject
      }
    } = this;
    const {
      data
    } = await request(['order.update.approve', {
      params: id,
      data: { approved: false }
    }]) || {};
    if (data && !data.detail) {
      callback({ order: data });
      reject();
    }
  };

  changeVisible(forcedVisible) {
    const {
      state: {
        visible
      }
    } = this;
    this.setState({
      visible: forcedVisible === undefined ? !visible : forcedVisible
    });
  }

  render() {
    const {
      props: {
        className = '',
        influencer: {
          avatar = '',
          avatar_base_url = '',
          first_name = '',
          last_name = ''
        } = {}
      },
      state: {
        visible
      },
      reject,
      changeVisible
    } = this;

    return (
      <>
        <ConfirmedDialog
          title="Decline this Application?"
          className="nexttime-dialog"
          titleClassName="nexttime-dialog-title"
          visible={visible}
          close={changeVisible}
          confirm={reject}
        >
          {
            <span className="nexttime-title flex-row flex-center">
              <span>Declining</span><span
              ><Avatar avatar={`${avatar_base_url}${avatar}`} className="nexttime-avatar" /></span
              ><span className="nexttime-underline">{assembleFullName(first_name, last_name)}</span>
            </span>
          }
        </ConfirmedDialog>
        <Button
          className={`btn-nexttime ${className}`}
          onClick={() => changeVisible(true)}
          colorSet="white"
        >
          Maybe Next Time
        </Button>
      </>
    );
  }
}
