import Dialog from '../Dialog';
import Button from '../Button';

import './index.css';

export default function  ConfirmedDialog(props = {}) {
  const {
    visible,
    children,
    className = '',
    title = '',
    titleClassName = '',
    single = false,
    close = new Function(),
    confirm = new Function(),
    cancel
  } = props;

  return (
    visible &&
      <Dialog
        className={className}
        showBack={false}
        visible={visible}
        close={() => close(false)}
      >
        <div className="flex-column flex-center full">
          {children}
          {
            <div className="flex-column flex-center">
              <div className={`confirmedialog-title ${titleClassName}`}>{title}</div>
              <div>
                {
                  single
                    ? <Button
                      className="btn-confirmedialog"
                      colorSet="white"
                      onClick={cancel || (() => close(false))}
                    >
                      Back
                    </Button>
                    : <>
                      <Button
                        className="btn-confirmedialog"
                        colorSet="white"
                        onClick={() => {
                          confirm();
                          close(false);
                        }}
                      >
                        Yes
                      </Button>
                      <Button
                        className="btn-confirmedialog"
                        colorSet="black"
                        onClick={cancel || (() => close(false))}
                      >
                        Cancel
                      </Button>
                    </>
                }
              </div>
            </div>
          }
        </div>
      </Dialog>
  );
}
