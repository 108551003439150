import { Component } from 'react';

import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';

import Logo from '../../components/Logo';
import ImageCarousel from '../../components/ImageCarousel';
import PlainLink from '../../components/PlainLink';
import Circle from './components/Circle';
import BgImgJpeg from '../../assets/images/bg/bg.jpeg'


import './index.css';
import './bg.css';

import authStore, { ROLE } from '../../store/user/auth';
import HomeSummary from './components/HomeSummary';

const auth = authStore();

const imageStyle = {
  width: '100vw',
  height: '76vw'
};

const images = [
  'home-bg-fashion',
  'home-bg-skincare',
  'home-bg-makeup',
  'home-bg-artculture',
  'home-bg-fitness'
];

const CAROUSEL = {
  fashion: {
    label: 'FASHION'
  },
  skincare: {
    label: 'SKINCARE'
  },
  makeup: {
    label: 'MAKE UP'
  },
  artculture: {
    label: 'ART&CULTURE'
  },
  fitness: {
    label: 'FITNESS'
  }
};
const roles = [2, 1];

export default class Home extends Component {
  constructor(props) {
    super(props);
    let status = new Array(Object.keys(CAROUSEL).length);
    status[0] = true;
    this.state = { emphysized: 0, status };
    this.setEmphysized = this.setEmphysized.bind(this);
  }

  setEmphysized(){
    let { emphysized, status } = this.state;
    status = [...status];
    status[emphysized] = false;
    emphysized = emphysized < Object.keys(CAROUSEL).length - 1 ? emphysized + 1 : 0;
    status[emphysized] = true;
    this.setState({ emphysized, status });
  }

  render() {
    const { emphysized, status } = this.state;
    return (
      <ImageCarousel
        images={images}
        image={BgImgJpeg}
        style={imageStyle}
        shadowClassName="image-shadow"
        callback={this.setEmphysized}
      >
        <div className="home-container">
          <Logo className="home-logo" />
          <div className="home-subtitle">A Place Where True Authentic Connections are Built</div>
          <HomeSummary />
          <div className="home-continue">
            {
              roles.map(each => {
                const role = ROLE[each];
                let { label, article } = role;
                label = label.toLowerCase();
                return (
                  <PlainLink
                    key={each}
                    to={`/bcom/${label}${auth.token != '' ? (label === 'brand' ? '/overview' : '/product') : '/signin'}`}
                    colorSet="white"
                  >
                    <div className="home-continue-role flex-row flex-start-center">
                      <div>Continue as {article} {label}</div>
                      <ArrowCircleRightOutlinedIcon
                        style={{ padding: '0.3255vw 0 0 0.9765vw' }}
                        sx={{ fontSize: 36 }}
                      />
                    </div>
                  </PlainLink>
                );
              })
            }
          </div>
          <div className="flex-row flex-start">
            {
              status.map((each, i) => (
                <div key={i}>
                  <Circle
                    key={i}
                    index={i}
                    className="home-circle"
                    emphysized={emphysized}
                    label={CAROUSEL[Object.keys(CAROUSEL)[i]].label}
                  />
                </div>
              ))
            }
          </div>
          <div className='home-footer'>
            Please read our&nbsp;
            <a href="https://baocommunications.com/general-terms-of-use/" target='_blank' rel="noreferrer">Term Of Use</a>&nbsp;&&nbsp;<a href="https://baocommunications.com/privacy-policy-bcom/" target='_blank' rel="noreferrer">Privacy Policy</a>
          </div>
        </div>
      </ImageCarousel>
    );
  }
};
