import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import './index.css';

import { withRouter } from 'react-router';

function Back(props = {}) {
  const {
    label,
    onClick,
    direction = 'back',
    history: {
      goBack
    },
    className = ''
  } = props;
  return (
    <div className={`${className} back flex-row flex-start-center`} onClick={onClick || goBack}>
      {
        direction == 'back' ?
          <ArrowBackIosNewIcon className="back-icon" />
          :
          null
      }
      <div>{label || 'Back'}</div>
      {
        direction == 'forward' ?
          <ArrowForwardIosIcon className="forward-icon"/>
          :
          null
      }
    </div>
  );
}

export default withRouter(Back);
