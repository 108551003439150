import StaticProgress from '../../../StaticProgress';

import './index.css';

import { calTimeDiff } from '../../../../utils/time';

export default function Timeline(props = {}) {
  const {
    className = '',
    status,
    start = '',
    end = '',
    current = ''
  } = props;
  const onGoing = current < end;

  return (
    <div className={`flex-column ${className}`}>
      <div className="timeline-top flex-row flex-between-center">
        <div className="timeline-end">End Date {`${[
          end.getDate(),
          end.getMonth() + 1,
          end.getFullYear()
        ].join('/')}`}</div>
        {
          onGoing &&
            status !== 'done' &&
            <div className="timeline-lefttime">{calTimeDiff(start, end).days} days left</div>
        }
      </div>
      {
        onGoing &&
          status !== 'done' &&
            <StaticProgress
              className="timeline-progress"
              colorSet="purple"
              value={20}
              total={50}
              height={4}
            />
      }
    </div>
  );
}
