import { Component } from 'react';
import Button from '../../../../../../../../../../components/Button';
import ConfirmedDialog from '../../../../../../../../../../components/ConfirmedDialog';
import Avatar from '../../../../../../../../../../components/Avatar';

import './index.css';

import { assembleFullName } from '../../../../../../../../../../utils/util';

export default class  ApproveDraftButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
    this.changeVisible = this.changeVisible.bind(this);
  }

  changeVisible(forcedVisible) {
    const {
      state: {
        visible
      }
    } = this;
    this.setState({
      visible: forcedVisible === undefined ? !visible : forcedVisible
    });
  }

  render() {
    const {
      props: {
        className = '',
        influencer: {
          avatar = '',
          avatar_base_url = '',
          first_name = '',
          last_name = ''
        } = {},
        confirm
      },
      state: {
        visible
      },
      changeVisible
    } = this;

    return (
      <>
        <ConfirmedDialog
          className="approvedraft-dialog"
          visible={visible}
          close={changeVisible}
          confirm={confirm}
        >
          {
            <>
              <span className="approvedraft-subtitle flex-row flex-center">
                <span>Approving draft from</span
                ><span><Avatar avatar={`${avatar_base_url}${avatar}`} className="approvedraft-avatar" /></span
                ><span className="approvedraft-title-underline">{assembleFullName(first_name, last_name)}</span>
              </span>
              <div className="approvedraft-title">Are you sure to approve this draft?</div>
            </>
          }
        </ConfirmedDialog>
        <Button
          className={className}
          onClick={() => changeVisible(true)}
        >
          Approve This Draft
        </Button>
      </>
    );
  }
}
