import Image from '../Image';

import { PLATFORM } from '../../constants/platforms';

import './index.css';

export default function Quota(props = {}) {
  const {
    platform,
    count = 0
  } = props;
  return (
    <div className="flex-row">
      <Image className="quota-platform" image={PLATFORM[platform.toLowerCase()].icon} />
      <div className="quota-count">x{count}</div>
    </div>
  );
}
