import { Component } from 'react';

import Card from '../../../../../../components/Card';
import Avatar from '../../../../../../components/Avatar';

import './index.css';

// import request from '../../../../../../../../service/request';

export default class PaymentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [
        {
          avatar: '',
          influencer_name: 'Blair Bao',
          brand_name: 'Dr. Jart',
          payment_status: 'Pending',
          payment_amount: 150
        },
        {
          avatar: '',
          influencer_name: 'Blair Bao',
          brand_name: 'Fresh',
          payment_status: 'Paid',
          payment_amount: 150
        },
        {
          avatar: '',
          influencer_name: 'Blair Bao',
          brand_name: 'Tom Ford',
          payment_status: 'Pending',
          payment_amount: 150
        },
        {
          avatar: '',
          influencer_name: 'Blair Bao',
          brand_name: 'Nike',
          payment_status: 'Pending',
          payment_amount: 150
        },
        {
          avatar: '',
          influencer_name: 'Blair Bao',
          brand_name: 'Chanel',
          payment_status: 'Pending',
          payment_amount: 150
        },
        {
          avatar: '',
          influencer_name: 'Blair Bao',
          brand_name: 'Fenty Beauty',
          payment_status: 'Pending',
          payment_amount: 150
        },
        {
          avatar: '',
          influencer_name: 'Blair Bao',
          brand_name: 'Armani',
          payment_status: 'Pending',
          payment_amount: 150
        }
      ]
    };
    this.load = this.load.bind(this);
  }

  async load() {
    // const { list: [] } = await request(['', {
    //   data: {}
    // }]) || '';
  }

  render() {
    const {
      state: {
        list
      }
    } = this;
    return (
      list.map((each, i) => {
        const {
          avatar,
          influencer_name,
          brand_name,
          payment_status,
          payment_amount
        } = each;
        return (
          <Card
            key={i}
            prefix={<Avatar className="overview-card-avatar" avatar={avatar} />}
            suffix={<div className="overview-card-suffix">${payment_amount}</div>}
            shadowed={false}
            className="brand-card"
          >
            <div className="overview-card flex-grow">
              <div className="overview-card-title">{influencer_name}</div>
              <div className="overview-card-subtitle">{brand_name}</div>
              <div className="overview-card-status">{payment_status}</div>
            </div>
          </Card>
        );
      })
    );
  }
}
