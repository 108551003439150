import { Link } from 'react-router-dom';
import './index.css';

export default function PlainLink (props = {}) {
  const {
    to,
    children,
    className = '',
    href = false,
    colorSet = '',
    style,
    underline = true
  } = props;
  return (
    href
      ? <a
        href={to}
        className={`${className} ${colorSet ? `link-font_${colorSet}` : ''} ${underline ? `link-underline_${colorSet}` : ''}`}
      >
        {children}
      </a>
      : <Link
        to={to}
        className={`${className} ${colorSet ? `link-font_${colorSet}` : ''} ${underline ? `link-underline_${colorSet}` : ''}`}
        style={{
          textDecoration: underline ? 'underline' : 'none',
          ...(style || {})
        }}
        onClick={() => href && (location.href=to)}
      >
        <div className={`link-font_${colorSet}`}>{children}</div>
      </Link>
  );
}
