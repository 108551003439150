import { makeAutoObservable } from 'mobx';

import { bindProperties } from '../../utils/object';
import { AUTH_TOKEN } from '../../service/config';

export const ROLE = {
  1: {
    label: 'Brand',
    article: 'a'
  },
  2: {
    label: 'Influencer',
    article: 'an'
  }
};

export default function () {
  const auth = {
    token: sessionStorage.getItem(AUTH_TOKEN) || '',
    authorized: false,
    role: undefined,
    getToken: function () {
      return sessionStorage.getItem(AUTH_TOKEN);
    },
    setToken: function (token) {
      this.token = token ? `Bearer ${token}` : '';
      sessionStorage.setItem(AUTH_TOKEN, this.token);
      this.setAuthorized(!!token);
      return this.token;
    },
    getAuthorized: function () {
      return this.authorized;
    },
    setAuthorized: function (authorized) {
      return (this.authorized = authorized);
    },
    getRole: function () {
      return this.role;
    },
    setRole: function (role) {
      return (this.role = role);
    },
    logout: function () {
      this.setToken('');
    }
  };

  bindProperties(auth);

  makeAutoObservable(auth);
  return auth;
}
