const INTEREST_IMG_ORIGIN = {
  FASHION: require('../assets/images/interest/interest-fashion.jpeg'),
  'SKIN CARE': require('../assets/images/interest/interest-skincare.jpeg'),
  BEAUTY: require('../assets/images/interest/interest-beauty.jpeg'),
  MAKEUP: require('../assets/images/interest/interest-makeup.jpeg'),
  COSMETICS: require('../assets/images/interest/interest-cosmetics.jpeg'),
  CLOTHES: require('../assets/images/interest/interest-clothes.jpeg'),
  ART: require('../assets/images/interest/interest-art.jpeg'),
  CULTURE: require('../assets/images/interest/interest-culture.jpeg'),
  HEALTH: require('../assets/images/interest/interest-health.jpeg'),
  FITNESS: require('../assets/images/interest/interest-fitness.jpeg'),
  NUTRITION: require('../assets/images/interest/interest-nutrition.jpeg'),
  TECHNOLOGY: require('../assets/images/interest/interest-technology.jpeg'),
  HOME: require('../assets/images/interest/interest-home.jpeg'),
  'LIFE STYLE': require('../assets/images/interest/interest-lifestyle.jpeg'),
  'WELL BEING': require('../assets/images/interest/interest-wellbeing.jpeg')
};

export const INTEREST_IMG_LIST = Object.keys(INTEREST_IMG_ORIGIN).reduce((prev, each) => {
  const name = each.toLowerCase().split(' ').join('');
  prev[name] = {
    label: each,
    name,
    image: `interest-bg-${name}`,
    origin: INTEREST_IMG_ORIGIN[each]
  };
  return prev;
}, {});
