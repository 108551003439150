import Card from '../Card';
import Image from '../Image';

import './index.css';

import { withRouter } from 'react-router';
import { BRAND_PATH } from '../../router/config';

function ProductCard (props = {}) {
  const {
    id = '',
    image,
    product,
    router = `${BRAND_PATH}/product/${id}`,
    history: {
      push
    }
  } = props;
  return (
    <Card
      {...props}
      top={<Image className="product-card-image" image={image} />}
      className="product-card"
      contentClassName="product-card-content"
      onClick={() => push({pathname: router, state: {product}})}
    />
  );
}

export default withRouter(ProductCard);
