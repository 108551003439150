import './index.css';

import Step from '../Step';

export default function Stepper(props = {}) {
  const {
    step = 0,
    selected = 0,
    steps = [],
    labelClassName = '',
    stepClassName = '',
    selectedClassName = '',
    unselectedClassName = '',
    onChange = new Function()
  } = props;
  return (
    <div className="flex-column">
      <div className="flex-row flex-start">
        {
          steps.map((each, i) =>
            <div key={i} className="flex-column flex-start">
              <div className={`stepper-label ${labelClassName}`}>
                {selected === i && each.label}
              </div>
              <div
                className={`stepper-step ${stepClassName} ${
                  selected === i
                    ? `stepper-step_selected ${selectedClassName}`
                    : `stepper-step_unselected ${unselectedClassName}`
                }`}
              />
            </div>
          )
        }
      </div>
      <div className="stepper-steps flex-row flex-between-center">
        {
          selected
            ? <Step
              labelClassName="stepper-steps-step"
              iconClassName="stepper-steps-icon"
              label="Review Back"
              onClick={() => onChange(Math.max(0, selected - 1))}
            />
            : <div></div>
        }
        {
          selected < step
            ? <Step
              labelClassName="stepper-steps-step"
              iconClassName="stepper-steps-icon"
              label="Review Forward"
              onClick={() => onChange(Math.min(selected + 1, step))}
              reverse
            />
            : <></>
        }
      </div>
    </div>
  );
}
