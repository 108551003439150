import { Component } from 'react';

import Search from '../../../../components/Search';
import Button from '../../../../components/Button';
import List from '../../../../components/List';
import ProductCard from '../../../../components/ProductCard';
import MakeCollabButton from '../../../../components/MakeCollabButton';
import EditProductButton from '../../../../components/EditProductButton';
import ProductAdd from './components/ProductAdd';
import LoadMore from '../../../../components/LoadMore';

import './index.css';

import { withRouter } from 'react-router';
import request from '../../../../service/request';

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addProductVisible: false,
      list: [],
      page: 1,
      total: 0,
      searchPage: 1,
      searchName: ''
    }
    this.addProduct = this.addProduct.bind(this);
    this.closeAddProduct = this.closeAddProduct.bind(this);
    this.saveProduct = this.saveProduct.bind(this);
    this.updateProduct = this.updateProduct.bind(this);
    this.queryProduct = this.queryProduct.bind(this);
    this.deleteFunc = this.deleteFunc.bind(this);
  }

  async queryProduct() {
    const {
      state: {
        list,
        page,
        searchPage,
        total,
        searchName
      }
    } = this;
    const {
      data: {
        count,
        results = []
      } = {}
    } = await request(['product.list', {
      query: {
        page: searchName ? searchPage : page,
        query: searchName || '',
        page_size: 9
      }
    }]) || '';
    this.setState({
      list: list.concat(results),
      page: searchName ? page : page + 1,
      searchName: searchName ? searchPage + 1 : searchPage,
      total: count || total
    });
  }

  addProduct() {
    this.setState({addProductVisible: true});
  }

  closeAddProduct() {
    this.setState({addProductVisible: false});
  }

  async saveProduct(params) {
    const {
      state: {
        list
      }
    } = this;
    const {
      productName,
      price,
      image,
      fit,
      description,
      isNotify
    } = params;
    const {
      data
    } = await request(['product.create', {
      data: {
        name: productName,
        price,
        images: image,
        categories: fit,
        description
        //link: 
      }
    }]);
    if(isNotify) {
      //
    }
    data && !data.detail && this.setState({
      list: list.concat(new Array(data))
    });
  }

  deleteFunc(id) {
    const { list } = this.state;
    this.setState({
      list: list.filter(each => each.id != id)
    });
  }

  async updateProduct(params) {
    const {
      id,
      productName,
      price,
      image,
      fit,
      description,
      isNotify
    } = params;
   		await request(['product.update', {
      params: id,
      data: {
        name: productName,
        price,
        images: image,
        categories: fit,
        description
        //link: 
      }
    }]);
    //console.log({data});
    //console.log({list});
    //let arr = list.map((each ,i) => {
    //  if(each.id == data.id) {
    //    each = data
    //  }
    //});
    if(isNotify) {
      //
    }
    //this.setState({
    //  list: arr
    //});
  }

  componentDidMount() {
    this.queryProduct();
  }

  render() {
    const {
      state: {
        list,
        total,
        addProductVisible
      }
    } = this;
    return (
      <div className="brand-product-list-container flex-column flex-start">
        <div className="brand-product-list-top width-inherit flex-row flex-center">
          <Search
            className="input-productsearch"
            btnClassName="btn-productsearch"
            placeholder="Search Product ..."
            onClick={ value => {
              this.setState({
                searchName: value,
                list: [],
                page: 1,
                searchPage: 1
              }, () => this.queryProduct(value));
            }}
          />
          <Button
            className="btn-productadd"
            colorSet="blue"
            onClick={this.addProduct}
          >
            Add New Product
          </Button>
        </div>
        <div className="width-inherit flex-row">
          <div className="flex-grow"></div>
          <List className="brand-product-list flex-start">
            {
              list.map((each, i) => {
                const {
                  id,
                  categories,
                  name,
                  image_base_url,
                  images
                } = each;
                return (
                  <div key={i} className="flex-column brand-product-listitem">
                    <ProductCard
                      product={each}
                      id={id}
                      title={categories.split(':')[0]}
                      subtitle={name}
                      image={`${image_base_url}${images.split(':')[0]}`}
                    />
                    <div className="flex-self-end link-editproduct flex-column flex-center-end">
                      <EditProductButton 
                        plain={true}
                        data={each}
                        updateProduct={this.updateProduct}
                        deleteFunc={this.deleteFunc}
                      />
                    </div>
                    <MakeCollabButton
                      product={each}
                      onClick={this.makeCollab}
                      className="btn-makecollab"
                      labelClassName="btn-makecollab-label"
                    />
                  </div>
                );
              })
            }
          </List>
          <div className="flex-grow"></div>
        </div>
        <div className="brand-product-list-bottom width-inherit flex-row flex-center">
          { total > list.length ? <LoadMore className="btn-loadmore-productlist" onClick={() => this.queryProduct('')} /> : <></> }
        </div>
        <ProductAdd
          visible={addProductVisible}
          saveFunc={this.saveProduct}
          deleteFunc={this.closeAddProduct}
          closeDialog={this.closeAddProduct}
        />
      </div>
    );
  }
};

export default withRouter(Product);
