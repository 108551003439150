export const platformName = (name) => {  
  switch (name) {
  case 'instagram':
    return 'Instagram';
  case 'instagram_story':
    return 'Instagram Story';
  case 'youtube':
    return 'Youtube';
  case 'tiktok':
    return 'Tiktok';
  default:
    return '';
  }
}