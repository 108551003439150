import { Component } from 'react';

export default class Circle extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { props: { index, emphysized, label, className } } = this;
    return (
      <div
        className={`flex-row flex-center ${className}`}
        style={{opacity: emphysized === index ? 1 : emphysized < index ? 0 : 0.7}}>
        <div className="flex-column flex-center">{label}</div>
      </div>
    );
  }
};
