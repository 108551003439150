import { Component } from 'react';
import './index.css'

export default class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {err: false}
  }
  render(){
    const {
      label,
      labelStyle,
      labelClassName,
      name,
      placeholder,
      className,
      type = 'text', //
      width = '29.68vw',
      height = '3.45vw',
      colorSet = 'white', //white,gray
      style = {},
      value,
      onChange,
      disabled = false,
      icon,
      isRequire = false,
      autocomplete,
      pattern = '',
      errMsg = '',
      endAdornment
    } = this.props;
    return (
      <div className="relative">
        <div className='flex-row flex-start-center'>
          {
            isRequire ?
              <div style={{'color': 'red', 'marginRight': '0.3255vw'}}>*</div>
              :
              <></>
          }
          {label && <div className={`label ${labelClassName}`} style={{...labelStyle}}>{label}</div>}
        </div>
        <div className="flex-row flex-start-center">
          <div className="input-icon flex-column flex-center">
            {icon}
          </div>
          <input
            name={name}
            className={`input input-color_${colorSet} ${className} ${icon ? 'input-icon-padding' : 'input-padding'}
						${isRequire ?
        				value == '' ?
          				// 'input-color_red'
          ''
          			: ''
        			: ''
      				}
      				`
            }
            type={type}
            required={isRequire}
            pattern={pattern}
            placeholder={placeholder}
            style={{ ...{width, height}, ...style }}
            value={value}
            onChange={onChange}
            disabled={disabled}
            autoComplete={autocomplete}
            onBlur={(event) => {
              if (pattern) {
                const re = new RegExp(pattern)
                if (!re.test(event.target.value)) {
                  this.setState({err: true})
                } else {
                  this.setState({err: false})
                }
              }
            }}
          />
          {endAdornment && (
            <div style={{
              position: 'absolute',
              right: '10px',
              transform: 'translateY(-50%)',
              cursor: 'pointer'
            }}>
              {endAdornment}
            </div>
          )}
        </div>
        {this.state.err && (
          <div style={{
            color: '#F00'
          }}>
            {errMsg}
          </div>
        )}
      </div>
    );
  }
}
