import Avatar from '../../../Avatar';

import { calTimeDiff } from '../../../../utils/util';

import './index.css'

const STATUS_SETTINGS = {
  unread: {
    colorSet: 'blue',
    label: 'Unread'
  },
  archived: {
    colorSet: 'purple',
    label: 'Archived'
  }
};

export default function InboxItem(props = {}) {
  const {
    data: {
      avatar_base_url = '',
      avatar = '',
      latest_message = '',
      date,
      name = '',
      has_new = ''
    } = {},
    className = '',
    onClick = new Function()
  } = props;
  const status = has_new ? 'unread' : 'archived';
  const statusSettings = STATUS_SETTINGS[status];

  return (
    <>
      <div className={`inbox-listitem ${className}`} onClick={onClick}>
        <div className='flex-row flex-between-center'>
          <div className='flex-row flex-start-center'>
            <Avatar
              avatar={`${avatar_base_url}${avatar}`}
              className="inbox-listitem-avatar"
            />
            <div className="inbox-listitem-name">{name}</div>
          </div>
          <div className="inbox-listitem-time">{calTimeDiff(date)}</div>
        </div>
        <div>
          <div className="inbox-listitem-content">{latest_message}</div>
          <div className='flex-row-reverse'>
            {
              statusSettings &&
            <div className={
              `flex-row flex-center inbox-listitem-status inbox-listitem-status_${statusSettings.colorSet}`
            }>
              {statusSettings.label}
            </div>
            }
          </div>
        </div>
      </div>
    </>
  );
}
