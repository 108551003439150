import {useState} from 'react';


function HomeSummary() {
  const [open, setOpen] = useState(false)
  return open ? (
    <div className="home-summary">
      BCOM is one-of-a-kind marketing platform promoting brands&apos; public interaction, visibility, build trust and amplify sales through connecting vetted influencers and consumers. Brands leverage on our curated community to gain authentic and instant business feedback by accessing data, social media posts and influencers&apos; reviews in exchange for a gifted product.
      <div style={{
        display: 'flex'
      }}>
        <button
          style={{
            background: 'none',
            border: 'none',
            color: '#888',
            fontSize: '36px',
            display: 'flex',
            width: '230px',
            gap: '20px',
            marginLeft: 'auto'
          }}
          onClick={() => setOpen(false)}
        >
          <svg style={{
            width: '40px'
          }} fill={'#888'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M246.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L224 109.3 361.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160zm160 352l-160-160c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L224 301.3 361.4 438.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3z"/>
          </svg>
          <div>
            About Us
          </div>
        </button>
      </div>
    </div>
  ) : (
    <>
      <div style={{
        width: '80%',
        border: '1px #fff solid'
      }} />
      <button
        style={{
          background: 'none',
          border: 'none',
          color: '#fff',
          fontSize: '36px',
          display: 'flex',
          width: '300px',
          gap: '20px'
        }}
        onClick={() => setOpen(true)}
      >
        <svg style={{
          width: '40px'
        }} fill={'#fff'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path d="M246.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 402.7 361.4 265.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3l-160 160zm160-352l-160 160c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 210.7 361.4 73.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3z"/>
        </svg>
        <div style={{
          marginTop: '3px'
        }}>
          About Us
        </div>
      </button>
    </>
  )
}


export default HomeSummary
