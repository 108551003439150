import { Component } from 'react';
import Button from '../../../../../../../../../../components/Button';
import Card from '../../../../../../../../../../components/Card';
import Input from '../../../../../../../../../../components/Input';
import TextArea from '../../../../../../../../../../components/TextArea';
import Avatar from '../../../../../../../../../../components/Avatar';

import './index.css';

import { assembleFullName } from '../../../../../../../../../../utils/util';

export default class  PublishCommentButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      name: '',
      title: '',
      comment: ''
    };
    this.changeVisible = this.changeVisible.bind(this);
  }

  changeVisible(forcedVisible) {
    const {
      state: {
        visible
      }
    } = this;
    this.setState({
      visible: forcedVisible === undefined ? !visible : forcedVisible
    });
  }

  render() {
    const {
      props: {
        className = '',
        influencer: {
          avatar = '',
          avatar_base_url = '',
          first_name = '',
          last_name = ''
        } = {},
        submit = new Function()
      },
      state: {
        visible,
        name,
        title,
        comment
      },
      changeVisible
    } = this;

    return (
      <>
        {
          visible &&
            <Card className="publishcomment-card scroll-y z-index-3 fixed">
              <div className="flex-column flex-start full-width">
                <Card className="flex-self-center">
                  <div
                    className="publishcomment-card-influencer flex-row flex-start-center"
                  >
                    <Avatar
                      className="publishcomment-card-avatar"
                      avatar={`${avatar_base_url}${avatar}`}
                      shape="square"
                    />
                    <div>{assembleFullName(first_name, last_name)}</div>
                  </div>
                </Card>
                <div className="publishcomment-card-thanks-top flex-self-center">
                  {'"Thank you for the opportunity!"'}
                </div>
                <div className="publishcomment-card-thanks">How did I do?</div>
                <div className="publishcomment-card-thanks">Leave a comment below to help me get better!</div>
                <div>
                  <div className="flex-row flex-start-center">
                    <div className="publishcomment-card-field">Your Name</div>
                    <Input
                      value={name}
                      onChange={evt => this.setState({ name: evt.target.value })}
                      className="publishcomment-card-input"
                    />
                  </div>
                  <div className="flex-row flex-start-center">
                    <div className="publishcomment-card-field">Job Title</div>
                    <Input
                      value={title}
                      onChange={evt => this.setState({ title: evt.target.value })}
                      className="publishcomment-card-input"
                    />
                  </div>
                </div>
                <TextArea
                  className="publishcomment-card-comment"
                  value={comment}
                  onChange={comment => this.setState({ comment })}
                  placeholder={'Type here...'}
                />
                <Button
                  className="btn-publishcomment"
                  onClick={() => {
                    this.setState({visible: false});
                    submit({ name, title, comment })
                  }}
                >
                  Submit
                </Button>
              </div>
            </Card>
        }
        <Button
          className={className}
          colorSet="white"
          onClick={() => changeVisible(true)}
        >
          Add Comment
        </Button>
      </>
    );
  }
}
