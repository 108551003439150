import { Component } from 'react';

import Input from '../Input';
import Button from '../Button';
import SearchIcon from '@mui/icons-material/Search';
import './index.css';

export default class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ''
    }
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    this.setState({
      value: event.target.value
    });
  }
	
  render() {
    const {
      state: {
        value
      },
      props: {
        className = '',
        btnClassName = '',
        btnColorSet = 'white',
        shrink,
        shadowed,
        bordered = true,
        onClick,
        placeholder,
        btnText
      }
    } = this;
    return (
      <div className="flex-row">
        <Input
          value={value}
          onChange={this.onChange}
          className={
            `flex-shrink ${bordered
              ? ''
              : 'border-none'} ${shadowed
              ? 'search-shadowed'
              : ''} input-search ${className}`
          }
          placeholder={placeholder || 'Search ...'}
          icon={<SearchIcon sx={{color: 'rgb(96, 0, 255)', height: '1.69vw' }}/>}
        >
        </Input>
        <Button
          className={
            `btn-search ${shrink
              ? 'btn-search_shrink'
              : ''} ${bordered
              ? ''
              : 'border-none'} ${shadowed
              ? 'search-shadowed'
              : ''} ${btnClassName}`
          }
          colorSet={btnColorSet}
          onClick={() => {onClick(value);}}
        >
          { btnText || 'Search' }
        </Button>
      </div>
    )
  }
}
