import Image from '../Image';
import './index.css';
import defaultAvatarImage from '../../assets/images/interest/interest-beauty.jpeg';

export default function Avatar (props =  {}) {
  const {
    avatar = '',
    shape = 'circle',
    className = '',
    style = {}
  } = props;
  return (
    <Image
      className={`${className} avatar-shape_${shape} flex-unshrink`}
      image={avatar || defaultAvatarImage}
      style={style}
    />
  );
};
