import { Component } from 'react';

import { formatDate } from '../../utils/util';

import './index.css';

// import request from '../../../../../../../../service/request';

export default class CommentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: []
    };
    this.load = this.load.bind(this);
  }

  async load() {
    // const { list: [] } = await request(['', {
    //   data: {}
    // }]) || '';
  }

  render() {
    const {
      props: {
        className = '',
        data = []
      }
    } = this;

    return (
      data.map((each, i) => {
        const {  
          is_send_from_brand,
          extra: {
            brand_name,
            brand_title,
            influencer_name
          }, 
          text, 
          created_date 
        } = each;
        const date = new Date(created_date);
        return (
          <div key={i} className={`comment-card flex-column bg_white ${className}`}>
            <div className="flex-row">
              {
                is_send_from_brand ?
                  <div className="flex-row">
                    <div className="comment-username">{brand_name}</div>
                    <div className="comment-position flex-grow">{brand_title}</div>
                  </div>
                  :
                  <div className="comment-username">{influencer_name}</div>
              }
             
            </div>
            <div className="comment-content">{text}</div>
            <div className="comment-date flex-row-reverse width-inherit">
              {formatDate(date.getFullYear(), date.getMonth(), date.getDay())}
            </div>
          </div>
        );
      })
    );
  }
}
