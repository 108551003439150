import axios from 'axios';

import {
  BASE_URL,
  REQUEST_CONFIG,
  ACTION_STRATEGIES,
  //ERROR_MSG,
  DEFAULT_PAGE_SIZE
} from './config';
import authStore from '../store/user/auth';
import errorHandler from '../service/errorHandler';
//import { isFunction } from '../utils/type';
import { assembleQuery } from '../utils/string';

const auth = authStore();


const server = axios.create({
  baseURL: BASE_URL
});

export default function ([trans_id, request] = [], option = {isLoading: true, isErrorHandler: true}) {
  const { isLoading, isErrorHandler } = option;
  isLoading;
  const [module, action, ...subModule ] = trans_id.split('.'); // action: 'create', 'read', 'update', 'list'
  const config = ACTION_STRATEGIES[action];
  Object.assign(config, (REQUEST_CONFIG[module] || {})[action] || {});
  const {
    method,
    authorized,
    pagination,
    page_size = DEFAULT_PAGE_SIZE,
    subRequest
  } = config;
  const { data, params, query, headers } = request || {};
  pagination && !query.page_size && (query.page_size = page_size);
  const requestParams = {
    method,
    url: `/api/${module}${
      subRequest
        ? `/${action}`
        : ''
    }${
      Array.isArray(params)
        ? `/${params[0] || params}`
        : params
          ? `/${params}`
          :''
    }${
      subModule
        ? subModule.reduce(
          (prev, each, i) =>
            `${prev}/${each}${Array.isArray(params) ? params[i + 1] ? `/${params[i + 1]}` : '' : ''}`,
          ''
        )
        : ''
    }${assembleQuery(query)}`,
    data,
    headers: {
      Authorization: authorized ? auth.getToken() : '',
      'Content-Type': 'application/json',
      //'Content-Type': 'multipart/form-data',
      ...headers
    }
  };
  return server(requestParams)
    .catch((error) => {
      let {
        response = {},
        //response: { status } = {},
        message
      } = error;
      if (response.status !== 404) {
        isErrorHandler && errorHandler(response, message);
        isLoading
      }
      return {
        ...response
        //message: (isFunction(errorHandler) && errorHandler()) ||
        //  ERROR_MSG[status] ||
        //  message
      };
    })
    .then((response) => {
      const {
        data: {
          access = ''
        }
      } = response;
      access && auth.setToken(access);
      isLoading
      return response;
    });
}
