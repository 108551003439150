//import React, { useState } from 'react';
import { Component } from 'react'

import Button from '../../../../../../../../components/Button';
import CollabRequirements from '../../../../../../../../components/CollabRequirements';
import Card from '../../../../../../../../components/Card';
import ApproveDraftButton from './components/ApproveDraftButton';
import Image from '../../../../../../../../components/Image';

import './index.css';

import request from '../../../../../../../../service/request';
import { formatStatistics } from '../../../../../../../../utils/util';

import { withRouter } from 'react-router';
import { BRAND_PATH } from '../../../../../../../../router/config';

class PublishedList  extends Component {
  constructor(props) {
    super(props);
    this.state = {
      linksTemp: this.props.links || []
    };
    this.approve = this.approve.bind(this);
  }

  async approve(draftId, i) {
    const {
      props: {
        id,
        next = new Function()
      },
      state: {
        linksTemp
      }
    } = this;
    const {
      data
    } = await request(['order.update.draft.approve', {
      params: [id, draftId]
    }]) || {};
    if (data && !data.detail) {
      linksTemp.map(each => {
        if(each.id == draftId) {
          each.is_approved = data.is_approved;
        }
      })
      this.setState({linksTemp});
      next({ pass: !linksTemp.some(each => !each.is_approved) });
    }
  }

  render() {
    const {
      props: {
        status,
        influencer = {},
        history: {
          push
        }
      }
    } = this;
    return (
      <div className="brandcollablist flex-row flex-wrap">
        {
          (this.state.linksTemp || []).map((each, i) => {
            const {
              id = '',
              url = '',
              link = '',
              is_approved = false,
              source: platform = '',
              image
            } = each;
            const {
              statistics: {
                likes = '',
                comments = '',
                views = ''
              },
              first_name = '',
              last_name = ''
            } = influencer;
            const unpublished = status !== 'published';
            const Title = unpublished ? 'div' : CollabRequirements;
	
            return (
              <div key={i} className={`brandcollabitem flex-column flex-start ${i%3 < 2 ? 'brandcollabitem-margin' : ''}`}>
                <div className="brandcollabitem-line">
                  {
                    unpublished ? 
                      <Image className='draft-card-image' onClick={() => window.open(url)}></Image>
                      :
                      <></>
                  }
                  {
                    <Title className={unpublished ? '' : 'brandcollabitem-title'} platform={platform}>
                      {
                        unpublished && is_approved ? 
                          <div className=''>
                            <div className='draft-card-approved'>You have approved</div>
                          </div>
                          :
                          <></>    
                      }
                      <a href={url || link} className={`brandcollabitem-link${unpublished ? '' : '-plain'}`} target='_blank' rel="noreferrer">{unpublished ? url : 'Link'}</a>
                    </Title>
                  }
                </div>
                {
                  status === 'published' &&
										<Card className="brandcollabitem-line flex-center">
										  <div className="brandcollabitem-card flex-column flex-start">
										    <Image 
										      className='published-card-collab-card-img'
										      image={image}
										    />
										    <div className='brandcollabitem-card-block flex-column flex-center-start'>
										      <div className="brandcollabitem-data flex-row flex-start-center">
										      <div className="brandcollabitem-statistics flex-row">
										        <div className="flex-grow" />
										        {formatStatistics(likes)}
										      </div>
													Likes
										    </div>
										    <div className="brandcollabitem-data flex-row flex-start-center">
										      <div className="brandcollabitem-statistics flex-row">
										        <div className="flex-grow" />
										        {formatStatistics(comments)}
										      </div>
													Comments
										    </div>
										    <div className="brandcollabitem-data flex-row flex-start-center">
										      <div className="brandcollabitem-statistics flex-row">
										        <div className="flex-grow" />
										        {formatStatistics(views)}
										      </div>
													Views
										    </div>
										    </div>
										  </div>
										</Card>
                }
                <div className="brandcollabitem-line flex-column">
                  {
                    unpublished && !is_approved &&
											(
											  <>
											    <ApproveDraftButton
											      className="btn-brandcollabitem"
											      influencer={influencer}
											      confirm={() => this.approve(id, i)}
											    />
											    <Button className="btn-brandcollabitem" onClick={() => push({pathname: `${BRAND_PATH}/inbox`, state: {name: `${first_name} ${last_name}`}})}>Need Improvement</Button>
											  </>
											)
                  }
                </div>
              </div>
            );
          })
        }
      </div>
    );
  }
}




//function PublishedList(props) {
//  const {
//    id,
//    status,
//    influencer = {},
//    links = [],
//    next = new Function(),
//    history: {
//      push
//    }
//  } = props;
//  const [linksTemp, setLinksTemp] = useState(links);
//  const approve = async (draftId, i) => {
//    const {
//      data
//    } = await request(['order.update.draft.approve', {
//      params: [id, draftId]
//    }]) || {};
//    console.log(data);
//    console.log(linksTemp);
//    if (data && !data.detail) {
//      linksTemp.map(each => {
//        if(each.id == draftId) {
//          each.is_approved = data.is_approved;
//        }
//      })
//      setLinksTemp(linksTemp);
//      setTimeout(()=> {
//        console.log(linksTemp);
//      }, 1000);
//      next({ pass: !linksTemp.some(each => !each.is_approved) });
//    }
//  };

//  return (
//    <div className="brandcollablist flex-row flex-wrap">
//      {
//        (linksTemp || []).map((each, i) => {
//          const {
//            id = '',
//            url = '',
//            link = '',
//            is_approved = false,
//            source: platform = ''
//          } = each;
//          console.log(id, is_approved);
//          const {
//            statistics: {
//              likes = '',
//              comments = '',
//              followers = ''
//            },
//            first_name = '',
//            last_name = ''
//          } = influencer;
//          let views = followers;
//          const unpublished = status !== 'published';
//          const Title = unpublished ? 'div' : CollabRequirements;

//          return (
//            <div key={i} className={`brandcollabitem flex-column flex-start ${i%3 < 2 ? 'brandcollabitem-margin' : ''}`}>
//              <div className="brandcollabitem-line">
//                {
//                  unpublished ? 
//                    <Image className='draft-card-image'></Image>
//                    :
//                    <></>
//                }
//                {
//                  <Title className={unpublished ? '' : 'brandcollabitem-title'} platform={platform}>
//                    {
//                      unpublished && is_approved ? 
//                        <div className=''>
//                          <div className='draft-card-approved'>You have approved</div>
//                        </div>
//                        :
//                        <></>    
//                    }
//                    <a href={url || link} className={`brandcollabitem-link${unpublished ? '' : '-plain'}`} target='_blank' rel="noreferrer">{unpublished ? url : 'Link'}</a>
//                  </Title>
//                }
//              </div>
//              {
//                status === 'published' &&
//                  <Card className="brandcollabitem-line flex-center">
//                    <div className="brandcollabitem-card flex-column flex-center-start">
//                      <div className="brandcollabitem-data flex-row flex-start-center">
//                        <div className="brandcollabitem-statistics flex-row">
//                          <div className="flex-grow" />
//                          {formatStatistics(likes&&likes[platform])}
//                        </div>
//                        Likes
//                      </div>
//                      <div className="brandcollabitem-data flex-row flex-start-center">
//                        <div className="brandcollabitem-statistics flex-row">
//                          <div className="flex-grow" />
//                          {formatStatistics(comments&&comments[platform])}
//                        </div>
//                        Comments
//                      </div>
//                      <div className="brandcollabitem-data flex-row flex-start-center">
//                        <div className="brandcollabitem-statistics flex-row">
//                          <div className="flex-grow" />
//                          {formatStatistics(views&&views[platform])}
//                        </div>
//                        Views
//                      </div>
//                    </div>
//                  </Card>
//              }
//              <div className="brandcollabitem-line flex-column">
//                {
//                  unpublished && !is_approved &&
//                    (
//                      <>
//                        <ApproveDraftButton
//                          className="btn-brandcollabitem"
//                          influencer={influencer}
//                          confirm={() => approve(id, i)}
//                        />
//                        <Button className="btn-brandcollabitem" onClick={() => push({pathname: `${BRAND_PATH}/inbox`, state: {name: `${first_name} ${last_name}`}})}>Need Improvement</Button>
//                      </>
//                    )
//                }
//              </div>
//            </div>
//          );
//        })
//      }
//    </div>
//  );
//}

export default withRouter(PublishedList);