const INTEREST_IMG_TYPE = [
  'FASHION',
  'SKIN CARE',
  'BEAUTY',
  'MAKEUP',
  'COSMETICS',
  'CLOTHES',
  'ART',
  'CULTURE',
  'HEALTH',
  'FITNESS',
  'NUTRITION',
  'TECHNOLOGY',
  'HOME',
  'LIFE STYLE',
  'WELL BEING'
];
export const INTEREST_IMG_LIST = INTEREST_IMG_TYPE.map(each => ({
  label: each,
  //image: `interest-bg-${each.toLowerCase().split(' ').join('')}`
  image: require(`../../../../../../assets/images/interest/interest-${each.toLowerCase().split(' ').join('')}.jpeg`)
}));