import { Component } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Image from '../Image';
import './index.css';

export default class FilterCheckBox extends Component {
  constructor(props) {
    super(props);
  }
	
  render() {
    const { label, filterList, onChange, className, checkBoxClassName, style, scroll } = this.props;
    return (
      <div>
        <div className="filter-check-box-label">{label}</div>
        <div className={`${scroll ? 'filter-check-box-block-scroll' : 'filter-check-box-block'} ${className}`}>
          {
            filterList.map((each, i) => {
              return (
                each.icon ?
                  <div key={i} className="flex-row flex-start-center">
                    <Checkbox
                      name={each.name}
                      value={each.value}
                      onChange={onChange}
                      className={checkBoxClassName}
                      sx={{
                        color: 'rgb(235, 235, 235)',
                        '&.Mui-checked': {
                          color: 'rgb(255, 82, 82)'
                        },
                        ...style
                      }
                      }
                    />
                    <Image image={each.icon} className="filter-check-box-img" />
                    <div className="filter-check-box-text">{each.name}</div>
                  </div>
                  :
                  <div key={i} className="flex-row flex-start-center">
                    <Checkbox
                      className={className}
                      name={each.name}
                      value={each.value}
                      onChange={onChange}
                      sx={{
                        color: 'rgb(235, 235, 235)',
                        '&.Mui-checked': {
                          color: 'rgb(255, 82, 82)'
                        },
                        ...style
                      }
                      }
                    />
                    <div className="filter-check-box-text">{each.name}</div>
                  </div>
              )
            })
          }
        </div>
      </div>
    );
  }
}
