import PublishedList from '../PublishedList';
import Button from '../../../../../../../../components/Button';

import { withRouter } from 'react-router';
import { BRAND_PATH } from '../../../../../../../../router/config';

import './index.css';

function ProductReceived(props) {
  const {
    className = '',
    data: {
      id,
      influencer,
      drafts = [],
      next
    } = {},
    history: {
      push
    }
  } = props;
  return (
    <div className={`flex-column ${className}`}>
      {
        drafts && drafts.length > 0 ?
          <PublishedList status="draft" id={id} influencer={influencer} links={drafts} next={next} />
          :
          <div className='flex-column flex-center'>
            <div className='product-received-comp-not-receiving'>Not receiving your order?</div>
            <Button className='product-received-comp-btn' onClick={() => {
              push(`${BRAND_PATH}/inbox`);
            }}>Contact Influencer</Button>
          </div>
      }
    </div>
  );
}

export default withRouter(ProductReceived);