import { Component } from 'react';

import Panel from '../../../../../../components/Panel';
import Overview from './components/Overview';
import List from '../../../../../../components/List';
import FollowerList from './components/FollowerList';
import BrandList from './components/BrandList';
import InterestList from './components/InterestList';
import CollaborationChart from './components/CollaborationChart';
import CommentList from './components/CommentList';

import './index.css';

import request from '../../../../../../service/request';
// import { parseQuery } from '../../../../../../utils/string';

const PANEL = [
  {
    Component: FollowerList,
    title: 'Followers',
    field: 'statistics.followers',
    colorSet: 'white',
    className: 'follower-list flex-between'
  },
  {
    Component: BrandList,
    title: 'Top Brands',
    field: 'top_brands',
    colorSet: 'white',
    className: 'brand-list flex-start scroll-y'
  },
  {
    Component: InterestList,
    title: 'Interests',
    field: 'interests',
    colorSet: 'white',
    className: 'interest-list flex-start-between scroll-y'
  },
  {
    Component: CollaborationChart,
    title: 'Collaboration Percentage',
    field: 'collobaration_percentage',
    colorSet: 'white',
    className: 'collab-chart-area'
  }
  //{
  //  Component: CommentList,
  //  title: 'Brand Comments',
  //  field: 'comments',
  //  colorSet: 'white',
  //  className: 'comment-list bg_lightpurple scroll-y',
  //  panelClassName: 'comment-panel'
  //}
];

export default class InfluencerDetails extends Component {
  constructor(props) {
    super(props);
    const { id = '' } = props.match.params;
    this.state = {
      id,
      data: {}
    };
    this.load = this.load.bind(this);
  }

  async load() {
    const {
      state: {
        id
      }
    } = this;
    const {
      data
    } = await request(['influencer.read', {
      params: id
    }]) || {};
    this.setState({ data });
  }

  async componentDidMount() {
    // const {
    //   props: {
    //     history: {
    //       location: {
    //         search
    //       }
    //     }
    //   }
    // } = this;
    // const data = JSON.parse(parseQuery(search).data);
    // data ? this.setState({ data }) : load();
    this.load();
  }

  render() {
    const {
      state: {
        data = {}
      }
    } = this;

    return (
      <div className="bg_lightpurple flex-row scroll">
        <Overview data={data} />
        <div className="bg_white flex-grow scroll">
          <List className="content-influencerdetails flex-column flex-start-between">
            {
              PANEL.map((each, i) => {
                const {
                  Component,
                  title,
                  //field,
                  colorSet,
                  className = '',
                  panelClassName= '',
                  style
                } = each;
                return (
                  <Panel
                    key={i}
                    title={title}
                    colorSet={colorSet}
                    className={`panel-influencerdetails bg_white ${panelClassName}`}
                    listClassName={className}
                    listStyle={style}
                  >
                    {/*<Component data={field.split('.').reduce((prev, child) => (prev && prev[child]), data)} />*/}
                    <Component data={data} />
                  </Panel>
                );
              })
            }
          </List>
        </div>
        <div className='flex-grow scroll'>
          <div className='influencer-details-comment-title'>Brand Comments</div>
          <CommentList
            data={data} 
            className='influencer-details-comment-list'
          />
        </div>
      </div>
    );
  }
}
