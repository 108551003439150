import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import './index.css';

export default function Step(props = {}) {
  const {
    label,
    // orientation,
    onClick = new Function(),
    className = '',
    labelClassName = '',
    iconClassName = '',
    reverse
  } = props;

  return (
    <div
      className={`step flex-row flex-start-center ${className} ${reverse ? 'flex-row-reverse' : ''}`}
      onClick={onClick}
    >
      {
        reverse
          ? <ArrowForwardIosIcon className={`step-icon ${iconClassName}`} />
          : <ArrowBackIosNewIcon className={`step-icon ${iconClassName}`} />
      }
      <div className={labelClassName}>{label}</div>
    </div>
  );
}


