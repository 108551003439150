import { Component } from 'react';

import Back from '../../../../../../components/Back';
import Image from '../../../../../../components/Image';
import PlainLink from '../../../../../../components/PlainLink';
import FoldedTextArea from '../../../../../../components/FoldedTextArea';
import MakeCollabButton from '../../../../../../components/MakeCollabButton';
import EditProductButton from '../../../../../../components/EditProductButton';
import Footer from '../../../../../../components/Footer';

import './index.css';

import request from '../../../../../../service/request';
import { formatStatistics } from '../../../../../../utils/util';

export default class ProductDetails extends Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: {
          id = ''
        } = {}
      } = {}
    } = props;
    this.state = {
      id,
      data: {}
    };
    this.load = this.load.bind(this);
  }

  async load() {
    const {
      state: {
        id
      }
    } = this;
    const {
      data
    } = await request(['product.read', {
      params: id
    }]) || {};
    this.setState({ data });
  }

  async componentDidMount() {
    // const {
    //   props: {
    //     history: {
    //       location: {
    //         search
    //       }
    //     }
    //   }
    // } = this;
    // const data = JSON.parse(parseQuery(search).data);
    // data ? this.setState({ data }) : load();
    this.load();
  }

  render() {
    const {
      state: {
        data: {
          name,
          image_base_url = '',
          images: images_base = '',
          //brand_name = '',
          link = '',
          price,
          description: details
        }
      },
      props: {
        location: {
          state: {
            product = {}
          } = {}
        }
      }
    } = this;

    const images = images_base.split(':');

    return (
      <div>
        <div className="product-details flex-column">
          <Back label={name} />
          <div className="product-details-image-list flex-column flex-wrap flex-start">
            {
              images.map((each, i) =>
                <Image
                  key={i}
                  image={`${image_base_url}${each}`}
                  className={`product-details-image product-details-image_${i ? 'small' : 'large'} flex-unshrink`}
                />
              )
            }
          </div>
          <div className="product-details-line flex-row">
            <div className="product-details-line-item flex-row">
              Product Link:&nbsp;<PlainLink to={link} href={true} colorSet="black">{name}</PlainLink>
            </div>
            <div className="product-details-line-item flex-row">
              Retail Price:&nbsp;${formatStatistics(price)}
            </div>
          </div>
          <FoldedTextArea
            className="product-details-content"
            title="Product Details"
            details={details}
          />
          <div className="flex-row">
            <EditProductButton
              data={product}
						 className="btn-productdetails-editproduct"
						 />
            <MakeCollabButton
              product={product}
              className="btn-productdetails-makecollab"
              labelClassName="btn-productdetails-makecollab-label"
            />
          </div>
        </div>
        <div>
          <Footer className="footer-productdetails" />
        </div>
      </div>
    );
  }
}
