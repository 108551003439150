import Avatar from '../../../Avatar';

import './index.css';

export default function AvatarList(props = {}) {
  const {
    className = '',
    itemClassName = '',
    avatar_base_url = '',
    list = []
  } = props;
  const { length } = list;
  return (
    <div className={`flex-row relative ${className}`}>
      {
        list.map((each, i) =>
          <Avatar
            key={i}
            className={`avatar-list-item_default ${itemClassName}`}
            avatar={`${avatar_base_url}${each}`}
            style={{ left: i * 20, zIndex: 9 - i }}
          />
        )
      }
      {
        length > 5 &&
          <div className="absolute flex-row flex-start-center" style={{ left: (length + 1) * 20 }}>
            <div className="avatar-list-ellipsis relative">......</div>
            <div
              className="avatar-list-item_default relative flex-row flex-center"
            >
              +{length - 5}
            </div>
          </div>
      }
    </div>
  );
}
