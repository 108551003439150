export default function StaticProgress(props = {}) {
  const {
    value = 0,
    total = 1,
    colorSet = 'purple',
    width,
    height = 4,
    className = ''
  } = props;
  return (
    <div
      className={`flex-row ${className}`}
      style={{ width: width ? `${width}px` : 'inherit', height: `${height}px` }}
    >
      <div
        className={`bg_${colorSet}`}
        style={{ width: `${(value / total) * 100 || 0}%`, height: `${height}px` }}
      />
      <div className={`flex-grow bg_light${colorSet}`} style={{ height: `${height}px` }} />
    </div>
  )
}
