import { redirect } from '../router';
import { AUTH_ROUTE } from '../router/config';
import { ROLE } from '../store/user/auth';
import { rootStore } from '../store';

const { auth, exception } = rootStore;
const role = auth.getRole();

export default function errorHandler(response, message) {
  const { 
    status, 
    data: {
      detail
    }
  } = response;
  switch(status) {
  case 401: 
    auth.setToken('');
    redirect(role
      ? AUTH_ROUTE.path.replace(':role', ROLE[role].label.toLowerCase())
      : '/'
    );
    break;
  default: 
    if(detail) {
      exception.message = detail;
    } else {
      exception.message = message
    }
  }
};
