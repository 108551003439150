import { Component } from 'react';
import Button from '../../../../../../../../../../components/Button';
import ConfirmedDialog from '../../../../../../../../../../components/ConfirmedDialog';
import Avatar from '../../../../../../../../../../components/Avatar';

import './index.css';

import { assembleFullName } from '../../../../../../../../../../utils/util';

export default class  ConfirmRateButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
    this.changeVisible = this.changeVisible.bind(this);
  }

  changeVisible(forcedVisible) {
    const {
      state: {
        visible
      }
    } = this;
    this.setState({
      visible: forcedVisible === undefined ? !visible : forcedVisible
    });
  }

  render() {
    const {
      props: {
        className = '',
        influencer: {
          avatar = '',
          avatar_base_url = '',
          first_name = '',
          last_name = ''
        } = {},
        submit
      },
      state: {
        visible
      },
      changeVisible
    } = this;

    return (
      <>
        <ConfirmedDialog
          className="submittrack-dialog"
          single={true}
          visible={visible}
          close={changeVisible}
          confirm={confirm}
        >
          {
            <>
              <div className="submittrack-title">Tracking link has been send to</div>
              <span className="submittrack-subtitle flex-row flex-center">
                <span><Avatar avatar={`${avatar_base_url}${avatar}`} className="submittrack-avatar" /></span
                ><span className="submittrack-title-underline">{assembleFullName(first_name, last_name)}</span>
              </span>
            </>
          }
        </ConfirmedDialog>
        <Button
          className={className}
          onClick={async () => {
            let res = await submit();
            if(res) {
              changeVisible(true);
            }
          }}
        >
          Confirm & Notify
        </Button>
      </>
    );
  }
}
