import Card from '../Card';
import List from '../List';

import './index.css';

export default function Panel (props = {}) {
  const {
    title = '',
    colorSet = '',
    children,
    bottom,
    shadowed = true,
    className = '',
    topClassName = '',
    bottomClassName = '',
    listClassName = '',
    style = {},
    topStyle = {},
    bottomStyle = {},
    listStyle = {}
  } = props;
  return (
    <div>
      <Card
        top={
          <div
            className={`panel-border panel-top bg_${colorSet} panel-title ${topClassName}`}
            style={topStyle}
          >
            {title}
          </div>
        }
        bottom={
          <div
            className={`panel-border panel-bottom bg_${colorSet} ${bottomClassName}`}
            style={bottomStyle}
          >
            {bottom}
          </div>
        }
        shadowed={shadowed}
        className={`panel ${className}`}
        style={style}
      >
        <List className={listClassName} style={listStyle}>{children}</List>
      </Card>
    </div>
  )
}
