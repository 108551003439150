import { Component } from 'react';
import Button from '../../../../../../../../../../components/Button';
import ConfirmedDialog from '../../../../../../../../../../components/ConfirmedDialog';
import Avatar from '../../../../../../../../../../components/Avatar';
import ProductCard from '../../../../../../../../../../components/ProductCard';

import './index.css';

import { assembleFullName } from '../../../../../../../../../../utils/util';

export default class  SelectButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
    this.changeVisible = this.changeVisible.bind(this);
  }

  changeVisible(forcedVisible) {
    const {
      state: {
        visible
      }
    } = this;
    this.setState({
      visible: forcedVisible === undefined ? !visible : forcedVisible
    });
  }

  render() {
    const {
      props: {
        className = '',
        influencer: {
          avatar = '',
          avatar_base_url = '',
          first_name = '',
          last_name = ''
        } = {},
        product: {
          id = '',
          name,
          categories = '',
          image_base_url = '',
          images = []
        } = {},
        select
      },
      state: {
        visible
      },
      changeVisible
    } = this;

    return (
      <>
        <ConfirmedDialog
          title="Confirm Collaboration?"
          className="select-dialog"
          titleClassName="select-dialog-title"
          visible={visible}
          close={changeVisible}
          confirm={select}
        >
          {
            <>
              <ProductCard
                id={id}
                title={(categories || '').split(':')[0]}
                subtitle={name}
                image={`${image_base_url}${images[0]}`}
                className=""
              />
              <span className="select-title flex-row flex-center">
                <span>Collaborating</span><span
                ><Avatar avatar={`${avatar_base_url}${avatar}`} className="select-avatar" /></span
                ><span className="select-underline">{assembleFullName(first_name, last_name)}</span>
              </span>
            </>
          }
        </ConfirmedDialog>
        <Button
          className={className}
          onClick={() => changeVisible(true)}
        >
          Select
        </Button>
      </>
    );
  }
}
