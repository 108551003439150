import { Component } from 'react';

import Back from '../../../../../../components/Back';
import Image from '../../../../../../components/Image';
import CollabRequirements from '../../../../../../components/CollabRequirements';
import PlainLink from '../../../../../../components/PlainLink';
import FoldedTextArea from '../../../../../../components/FoldedTextArea';
// import MakeCollabButton from '../../../../../../components/MakeCollabButton';
import Filter from './components/Filter';
import InfluencerList from './components/InfluencerList';
import InfluencerBusiCard from '../../../../../../components/InfluencerBusiCard';
import Step from '../../../../../../components/Step';
import Stepper from '../../../../../../components/Stepper';
import Footer from '../../../../../../components/Footer';

import Start from './components/Start';
import Selected from './components/Selected';
import RateConfirmed from './components/RateConfirmed';
import ProductReceived from './components/ProductReceived';
import DraftApproved from './components/DraftApproved';
import Published from './components/Published';

import './index.css';

import request from '../../../../../../service/request';
import { formatStatistics } from '../../../../../../utils/util';

const COLLAB_STEPS = [
  {
    label: 'Start',
    value: 'Start',
    component: Start
  },
  {
    label: 'Selected',
    value: 'Selected',
    component: Selected
  },
  {
    label: 'Rate Confirmed',
    value: 'Rate Confirmed',
    component: RateConfirmed
  },
  {
    label: 'Product Received',
    value: 'Product Received',
    component: ProductReceived
  },
  {
    label: 'Draft Approved',
    value: 'Draft Approved',
    component: DraftApproved
  },
  {
    label: 'Published!',
    value: 'Published',
    component: Published
  }
];

export default class CollabDetails extends Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: {
          id = ''
        } = {}
      } = {}
    } = props;
    this.state = {
      blank: true,
      id: parseInt(id),
      data: {},
      orders: [],
      influencers: [],
      selected: 0,
      order: {},
      step: 0,
      selectedStep: 0
    };
    this.load = this.load.bind(this);
    this.findStep = this.findStep.bind(this);
    this.changeInfluencer = this.changeInfluencer.bind(this);
  }

  async load() {
    const {
      state: {
        id
      }
    } = this;
    let data = await request(['collaboration.read', {
      params: id
    }]).catch(() => ({}));
    data = data.data || {};
    const orders = data.orders || [];
    const influencers = orders.map(each => each.influencer);
    const step = orders.length > 0 ? COLLAB_STEPS.findIndex(each => each.value === orders[0].state) : 0;
    this.setState({
      data,
      orders,
      order: orders[0],
      influencers,
      step,
      selectedStep: step,
      blank: orders.length > 0 ? false : true
    });
  }

  findStep(order) {
    return COLLAB_STEPS.findIndex(each => each.value === order.state);
  }

  changeInfluencer(index) {
    const {
      state: {
        orders
      },
      findStep
    } = this;
    const order = orders[index];
    const step = findStep(order);
    this.setState({
      selected: index,
      order: orders[index],
      step,
      selectedStep: step
    });
  }

  async componentDidMount() {
    // const {
    //   props: {
    //     history: {
    //       location: {
    //         search
    //       }
    //     }
    //   }
    // } = this;
    // const data = JSON.parse(parseQuery(search).data);
    // data ? this.setState({ data }) : load();
    this.load();
  }

  render() {
    const {
      state: {
        blank,
        data: {
          product,
          product: {
            name,
            image_base_url = '',
            images: images_base = '',
            //brand_name = '',
            link = '',
            price,
            description: details
          } = {},
          quota = {},
          requirements: collab_details
        },
        orders = [],
        influencers = [],
        selected = 0,
        order = {},
        step = 0,
        selectedStep = 0
      },
      changeInfluencer
    } = this;
    const StepComponent = COLLAB_STEPS[selectedStep].component;

    const images = images_base.split(':');

    return (
      <div>
        <div className="brand-collab-details flex-column">
          <Back label={name} />
          <div className="brand-collab-details-top flex-row flex-start">
            <Image
              image={`${image_base_url}${images[0]}`}
              className="brand-collab-details-image height-inherit"
            />
            <div className="flex-column flex-between height-inherit">
              {/* <MakeCollabButton
                className="btn-brandcollabdetails-makecollab"
                labelClassName="btn-brandcollabdetails-makecollab-label"
              /> */}
              <div></div>
              <div >
                {
                  Object.keys(quota || []).map((each, i) =>
                    <div key={i} className="brand-collab-details-quota">
                      <CollabRequirements
                        className="brand-collab-details-quota-inner"
                        platform={each}
                        amount={quota[each]}
                        editable={false}
                      />
                    </div>
                  )
                }
              </div>
            </div>
          </div>
          <div className="brand-collab-details-line flex-row">
            <div className="brand-collab-details-line-item flex-row">
              Product Link:&nbsp;<PlainLink to={link} href={true} colorSet="black">{name}</PlainLink>
            </div>
            <div className="brand-collab-details-line-item flex-row">
              Retail Price:&nbsp;${formatStatistics(price)}
            </div>
          </div>
          <FoldedTextArea
            className="brand-collab-details-content"
            title="Product Details"
            details={details}
          />
          <FoldedTextArea
            className="brand-collab-details-content"
            title="Campaign Details"
            details={collab_details}
          />
          {/* <div className="flex-row">
            <EditProductButton className="btn-brandcollabdetails-editproduct"/>
          </div> */}
          <div className="flex-column flex-start-center">
            {
              influencers && influencers.length > 0 ?
                <div className="flex-column flex-start-center">
                  <Filter />
                  <InfluencerList
                    list={influencers}
                    selected={selected}
                    onClick={changeInfluencer}
                  />
                  <InfluencerBusiCard
                    className="brand-collab-details-influencercard"
                    data={influencers[selected]}
                  />
                </div>
                :
                <div></div>
            }
            <div>
              <Stepper
                step={COLLAB_STEPS.findIndex(each => each.value === order.state)}
                steps={COLLAB_STEPS}
                selected={selectedStep}
                onChange={selectedStep => this.setState({ selectedStep })}
              />
              {
                blank
                  ? <div className="brand-collab-details-stepcontent" />
                  : <StepComponent
                    className="brand-collab-details-stepcontent"
                    id={order.id}
                    data={{ ...order, product: { ...product, images } }}
                    next={({ order, pass } = {}) => {
                      let step = Math.min(COLLAB_STEPS.length, selectedStep + (pass ? 1 : 0));
                      if (order && order.id) {
                        orders[selected] = { ...orders[selected], order };
                        this.setState({
                          orders,
                          order
                        });
                      }
                      this.setState({
                        step,
                        selectedStep: step
                      })
                    }}
                    editable={step === selectedStep}
                    reject={() => this.setState({ blank: true })}
                  />
              }
              <div className="full-width flex-row flex-between">
                <Step
                  label="Previous Influencer"
                  onClick={() => changeInfluencer(Math.max(0, selected - 1))}
                />
                <Step
                  label="Next Influencer"
                  onClick={() => changeInfluencer(Math.min(influencers.length - 1, selected + 1))}
                  reverse
                />
              </div>
            </div>
          </div>
        </div>
        <Footer className="footer-brandcollabdetails" />
      </div>
    );
  }
}
