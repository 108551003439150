import { Component } from 'react';

import Back from '../../../../components/Back';
import Inbox from '../../../../components/Inbox';
import Avatar from '../../../../components/Avatar';

import './index.css';

import { withRouter } from 'react-router';

class BrandInbox extends Component {

  constructor(props) {
    super(props);
    this.state = {
      room: {}
    }
    this.callback = this.callback.bind(this);
  }

  callback(room) {
    this.setState({room});
  }

  render() {
    const {
      state: {
        room,
        room: {
          avatar_base_url = '',
          avatar = ''
        },
        location: {
          state: {
            name = ''
          } = {}
        } = {}
      }
    }  = this;
    return (
      <div className='brand-inbox-container'>
        <Back className='brand-inbox-back'></Back>
        <div className='flex-row'>
          <div className='flex-row flex-start'>
            <Inbox name={name} callback={this.callback} className='brand-inbox-message'/>
          </div>
          <div className='brand-inbox-avatar-container flex-column flex-center'>
            <Avatar 
              avatar={`${avatar_base_url}${avatar}`}
              className="brand-inbox-avatar"
            />
            <div className='brand-inbox-avatar-name'>{room.name}</div>
          </div>
        </div>
      </div>
    );
  }
};

export default withRouter(BrandInbox);