import { Component } from 'react';
import Brand from './components/Brand';
import Influencer from './components/Influencer';

import { toCamel } from '../../utils/string';

const children = {
  Brand,
  Influencer
};

export default class Signup extends Component {
  render() {
    const { match: { params: { role } } } = this.props;
    const Component = children[toCamel(role)];
    return <Component />
  };
};
