import { Component } from 'react';

import Filter from './components/Filter';
import Search from '../../../../components/Search';
import List from '../../../../components/List';
import InfluencerCard from '../../../../components/InfluencerCard';
import LoadMore from '../../../../components/LoadMore';

import './index.css';

import request from '../../../../service/request';

export default class Influencer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      page: 1,
      total: 0,
      categories: [],
      platformList: [],
      sortBy: [],
      levelList: [],
      searchName: '',
      searchPage: 1
    };
    this.load = this.load.bind(this);
  }

  async load() {
    const {
      state: {
        list,
        page,
        categories,
        platformList,
        sortBy,
        levelList,
        searchName,
        searchPage
      }
    } = this;
    let category = [];
    categories.map(each => {
      if(each.checked === true) {
        category.push(each.value);
      }
    })
    let platform = [];
    platformList.map(each => {
      if(each.checked === true) {
        platform.push(each.value);
      }
    });
    let level = [];
    levelList.map(each => {
      if(each.checked === true) {
        level.push(each.value);
      }
    });
    const {
      data: {
        count = 0,
        results = []
      } = {}
    } = await request(['influencer.list', {
      query: {
        page: searchName ? searchPage : page,
        category: category.join(','),
        platform_accounts: platform.join(','),
        order_by: sortBy,
        follower_counts: level.join(','),
        query: searchName
      }
    }]) || '';
    this.setState({
      list: list.concat(results),
      page: searchName ? page : page + 1,
      searchPage: searchName ? searchPage + 1 : searchPage,
      total: count
    });
  }

  componentDidMount() {
    this.load();
  }

  render() {
    const {
      state: {
        total,
        list
      },
      load
    } = this;

    return (
      <div className="flex-row">
        <div className="influencer-list-filter flex-unshrink scroll-y">
          <Filter searchCollab={data => {
            const {
              categories,
              platformList,
              sortBy,
              levelList
            } = data;
            this.setState({
              categories,
              platformList,
              sortBy,
              levelList,
              page: 1,
              searchPage: 1,
              list: []
            }, () => {
              this.load();
            });
          }}/>
        </div>
        <div className="scroll">
          <div className="influencer-list-content flex-unshrink">
            <div className="flex-row flex-center">
              <Search
                className="influencer-list-search"
                shrink={true}
                placeholder="Search Pepole ..."
                onClick={searchName => {
                  this.setState({
                    list: [], 
                    searchName, 
                    page: 1, 
                    searchPage: 1 
                  }, () => {
                    this.load();
                  });
                }}
              />
            </div>
            <div className="influencer-list flex-center">
              <List>
                {
                  list.map((each, i) => (
                    <div key={i} className="influencer-list-item">
                      <InfluencerCard data={each} />
                    </div>
                  ))
                }
              </List>
              <div className="flex-row flex-center">
                { total > list.length ? <LoadMore className="btn-loadmore-influencerlist" onClick={load} /> : <></> }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
