import { Component } from 'react';

import Avatar from '../../../../../../components/Avatar';

import './index.css';

import { withRouter } from 'react-router';
import request from '../../../../../../service/request';
import { assembleFullName } from '../../../../../../utils/util';

class InfluencerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: []
    };
    this.load = this.load.bind(this);
  }

  async load() {
    const {
      data: {
        results: list = []
      } = {}
    } = await request(['influencer.list', {
      query: {
        page: 1
      }
    }]) || '';
    this.setState({ list });
  }

  componentDidMount() {
    this.load();
  }

  render() {
    const {
      state: {
        list
      }
    } = this;
    return (
      list.map((each, i) => {
        const {
          avatar_base_url,
          avatar,
          first_name,
          last_name
        } = each;
        return (
          <div
            key={i}
            className="overview-influencerlist-item flex-column flex-start-center"
          >
            <Avatar className="overview-influencerlist-avatar" avatar={`${avatar_base_url}${avatar}`} />
            <div className="overview-influencerlist-name">{assembleFullName(first_name, last_name)}</div>
          </div>
        );
      })
    );
  }
}

export default withRouter(InfluencerList);
